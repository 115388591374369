import { isObject } from '@/helpers'
import { ErrorResponse } from '@/api/api'
import { z } from 'zod'
import { buildAssetManagementUrl } from '@/shared/utils/urls/builders'

type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'HEAD'

// function initAppend(init: RequestInit|undefined, body: unknown) {
//   return { ...(init || {}), body: JSON.stringify(body) }
// }

function normalizeUrl(base: URL|string, path: string) {
  const merged = new URL(base)
  merged.pathname = `${merged.pathname.replace(/\/+$/, '')}/${path.replace(/^\/+/, '')}`

  return merged
}

export function createClient(baseUrl: URL|string) {
  return {
    sendGet: (path: string) => sendRequest('GET', normalizeUrl(baseUrl, path)),
    sendPost: (path: string, body: unknown) => sendRequest('POST', normalizeUrl(baseUrl, path), body),
    sendPut: (path: string, body: unknown) => sendRequest('PUT', normalizeUrl(baseUrl, path), body),
    sendPatch: (path: string, body: unknown) => sendRequest('PATCH', normalizeUrl(baseUrl, path), body),
    sendDelete: (path: string) => sendRequest('DELETE', normalizeUrl(baseUrl, path)),
    buildUrl: (path: string) => normalizeUrl(baseUrl, path).toString()
  }
}

// export const sendGetRequest = (url: URL, init?: RequestInit) => sendRequest({ url, init, method: 'get' })
// export const sendPatchRequest = (url: URL, body?: unknown, init?: RequestInit) => sendRequest({ url, init: initAppend(init, body), method: 'patch' })
// export const sendPostRequest = (url: URL, body?: unknown, init?: RequestInit) => sendRequest({ url, init: initAppend(init, body), method: 'post' })
// export const sendPutRequest = (url: URL, body?: unknown, init?: RequestInit) => sendRequest({ url, init: initAppend(init, body), method: 'put' })
// export const sendDeleteRequest = (url: URL, init?: RequestInit) => sendRequest({ url, init, method: 'delete' })

async function sendRequest(method: HttpMethod, url: URL, body?: unknown, init?: RequestInit) {
  // Ensure we always have an object.
  init = init || { }

  // Always send the necessary credentials.
  init.credentials = "include"

  // Override the method.
  init.method = method.toUpperCase()

  // Always populate headers.
  init.headers = new Headers(init.headers)

  // Ensure we accept JSON as a response.
  init.headers.set('accept', 'application/json')

  if (body !== undefined) {
    init.headers.set('content-type', 'application/json')
    init.body = JSON.stringify(body)
  }

  return fetch(url.toString(), init)
}
// sendRequest(okSchema, notOkSchema)
async function sendRequest2({ method, url, init } : { method: HttpMethod, url: URL, init?: RequestInit }): Promise<Response> {
  // Ensure we always have an object.
  init = init || {}

  // Always send the necessary credentials.
  init.credentials = "include"

  // Override the method.
  init.method = method.toUpperCase()

  // Always populate headers.
  init.headers = new Headers(init.headers)

  // Ensure we accept JSON as a response.
  init.headers.set('accept', 'application/json')

  if (!init.headers.has('content-type')) {
    init.headers.set('content-type', 'application/json')
  }

  return fetch(url.toString(), init)
}
