<script setup lang="ts">
import { inject } from 'vue'
import { CardInjectionKey } from '@/shared/types/injections'

withDefaults(
  defineProps<{
    size?: 'small' | 'normal'
  }>(),
  { size: 'normal' }
)

const ctx = inject(CardInjectionKey)

if (ctx === undefined) {
  throw new Error("[CardHeader] can only be used inside of a [Card] component.")
}

const placement = ctx.headerPlacement
</script>

<template>
  <div :class="[
         $style[`placement-${placement}`],
         $style['card-header'],
         { [$style.small]: size === 'small' }
       ]"
       class="d-flex justify-content-between align-items-center">
    <slot />
  </div>
</template>

<style module lang="scss">
$border-radius: .625rem;

.placement-outside {
  padding-left: 12px;
  margin-bottom: 7px;
  font-weight: 500;
}

.placement-inside {
  font-weight: 500;
  padding: .625rem .75rem;
  background: #eee;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  &.small {
    padding: .375rem .75rem;
  }
}

.card-header {
  font-size: 13px;
}
</style>
