import makeApiClient, { Type } from '@/api/api'
import { z } from 'zod'
import { assertSchema } from '@/helpers/assert-schema'

export type OrganisationList = z.infer<typeof schema>

export const schema = z.array(
  z.object({
    id: z.number(),
    name: z.string()
  })
)

const { sendGetRequest } = makeApiClient(Type.Core)

export default async function getOrganisationList() {
  const { data } = await sendGetRequest(`organisations`)
  assertSchema(data, schema)
  return data
}
