import { z } from 'zod'
import makeApiClient, { Type } from '@/api/api'
import { assertSchema } from '@/helpers/assert-schema'

export type OrganisationDetails = z.infer<typeof schema>

export const schema = z.object({
  id: z.number(),
  encryptedId: z.string(),
  name: z.string(),
  imageUrl: z.string().nullable(),
  contactNumber: z.string().nullable(),
  companyRegistrationNumber: z.string().nullable(),
  websiteUrl: z.string().nullable(),
  addressLine1: z.string().nullable(),
  addressLine2: z.string().nullable(),
  suburb: z.string().nullable(),
  city: z.string().nullable(),
  postalCode: z.string().nullable(),
  countryId: z.number().int().gt(0).nullable()
})

const { sendGetRequest } = makeApiClient(Type.Core)

export default async function getOrganisationDetails(id: number): Promise<OrganisationDetails> {
  const { data } = await sendGetRequest(`organisations/${id}`)

  assertSchema(data, schema)

  return data
}
