<script setup lang="ts">
import type { RouterLinkProps } from 'vue-router'
import { type BaseButtonProps, baseButtonDefaults } from '@/shared/types/components'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<RouterLinkProps & BaseButtonProps>(),
  { ...baseButtonDefaults }
)

const classes = computed(
  () => [
    'btn',
    {
      'btn-sm': props.size === 'small',
      'btn-lg': props.size === 'large',
      'rounded-pill': props.rounded,
      'btn-shadow': props.shadow
    }
  ]
)

const externalLinkRegex = /^https?:\/\//

</script>

<template>
  <a v-if="state === 'disabled'" v-bind="$attrs" aria-disabled="true" href="javascript:void(0)" :class="classes">
    <FontAwesomeIcon :icon="icon" class="me-1" v-if="icon && iconPosition === 'before'" />
    <slot />
    <FontAwesomeIcon :icon="icon" class="ms-1" v-if="icon && iconPosition === 'after'" />
  </a>
  <a v-else-if="typeof to === 'string' && externalLinkRegex.test(to)" :href="to" v-bind="$attrs" :class="classes">
    <FontAwesomeIcon :icon="icon" class="me-1" v-if="icon && iconPosition === 'before'" />
    <slot />
    <FontAwesomeIcon :icon="icon" class="ms-1" v-if="icon && iconPosition === 'after'" />
  </a>
  <RouterLink v-else v-bind="{ ...$props, ...$attrs }" :class="classes">
    <FontAwesomeIcon :icon="icon" class="me-1" v-if="icon && iconPosition === 'before'" />
    <slot />
    <FontAwesomeIcon :icon="icon" class="ms-1" v-if="icon && iconPosition === 'after'" />
  </RouterLink>

</template>
