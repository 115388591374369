import { type MutationFunction, type QueryOptions, useMutation, useQueryClient } from '@tanstack/vue-query'

type AllowedQueryKeys<TVariables> = QueryOptions['queryKey'] | ((input: TVariables) => QueryOptions['queryKey'])

export function useMutationWithInvalidation<TData, TVariables>(mutationFn: MutationFunction<TData, TVariables>, keys: AllowedQueryKeys<TVariables>[]) {
  const client = useQueryClient()

  return useMutation({
    mutationFn,
    async onSuccess(output, input) {
      await Promise.all(
        keys.map(
          queryKey => {
            if (Array.isArray(queryKey)) {
              return client.invalidateQueries({ queryKey })
            }

            if (typeof queryKey === 'function') {
              return client.invalidateQueries({ queryKey: queryKey(input) })
            }

            return Promise.resolve()
          }
        )
      )
    }
  })
}
