<script setup lang="ts">
import type { ZodSchema } from "zod";
import { computed, ref, toRef } from "vue";
import { withoutKeys } from "@/helpers";
import { faCalendarDays, faClock } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useUnifiiField } from "@/composables/inputs";
import { format } from "date-fns/format";
import { DateFormats } from "@/helpers/formatting";

// Prevent attribute inheritance.
defineOptions({ inheritAttrs: false })

const props = withDefaults(
  defineProps<
    Partial<{
      name: string
      value: string | Date | null
      rules: ZodSchema,
      type: 'date' | 'datetime-local' | 'time' /*| 'month' | 'week' */
      inline: boolean
      required: boolean
      min: string | Date
      max: string | Date
      errorVariant?: 'inline' | 'tooltip'
    }>
  >(),
  { name: '', value: null, type: 'date', errorVariant: 'inline' }
)

const $element = ref()
const $emit = defineEmits<{ 'update:value': [Date|undefined] }>()

const {
  errorMessage,
  value: currentValue,
  handleBlur,
  handleChange,
  handleInput
} = useUnifiiField({
  name: toRef(props, 'name'),
  rules: toRef(props, 'rules'),
  initialValue: toRef(props, 'value'),
  emitter: (value: Date|undefined) => $emit('update:value', value),
})

const toggleIcon = computed(
  () => {
    // If the browser doesn't support hiding the picker icon, then don't show a custom one.
    if (!window.CSS.supports('selector(::-webkit-calendar-picker-indicator)')) {
      return undefined
    }

    if (props.type === 'time') {
      return faClock
    }

    return faCalendarDays
  }
)

const formattedValue = computed(
  () => currentValue.value instanceof Date
    ? format(currentValue.value, 'yyyy-LL-dd')
    : currentValue.value
)
</script>

<template>
    <div :class="{ 'd-inline-block': inline, 'd-block': !inline }">
      <div
        class="input-group position-relative"
        :class="[
          {
            'has-validation': errorMessage,
            [$style['has-toggle']]: toggleIcon !== undefined,
            [$style['required']]: required
          },
          $style['input-date'],
          $attrs.class
        ]">
        <input
          ref="$element"
          v-bind="withoutKeys($attrs, ['class'])"
          :name="name"
          :value="formattedValue"
          :type="type"
          class="form-control"
          :min="min ? format(min, DateFormats.YearMonthDay) : undefined"
          :max="max ? format(max, DateFormats.YearMonthDay) : undefined"
          :class="{ 'is-invalid': errorMessage }"
          v-tooltip="errorVariant === 'tooltip' ? { text: errorMessage, variant: 'error' } : undefined"
          @change="handleChange"
          @blur="handleBlur"
          @input="handleInput"
        />
        <div class="invalid-tooltip" v-if="errorVariant === 'inline' && errorMessage">{{ errorMessage }}</div>

        <span v-if="toggleIcon" @click="() => { !$attrs.disabled && $element.showPicker?.() }" class="input-group-text">
          <FontAwesomeIcon :icon="toggleIcon" style="width: 15px" />
        </span>
      </div>
    </div>
<!--  </Field>-->
</template>

<style module lang="scss">
@use "@/assets/styles/mixins-inputs";
@use "@/assets/mixins/inputs";

@import "@/assets/variables";

.input-date {
  @include mixins-inputs.fix-error-tooltips-for-inputs;

  // Hide calendar picker icon.
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
  // Apply styling for prefixes/suffixes.
  @include inputs.apply-prefix-and-suffix-styles;

  //:global(.form-control.is-invalid) ~ .toggle {
  //  border-color: var(--bs-form-invalid-border-color);
  //}
  //
  //.toggle {
  //  background: $body-bg;
  //  transition: $input-transition;
  //  border-left: none;
  //}
  //
  //&:focus-within {
  //  .toggle {
  //    border-color: $input-focus-border-color;
  //  }
  //}
}

//.has-toggle {
//  :global(.form-control) {
//    border-right: none;
//  }
//}

.required {
  @include inputs.apply-required-indicator-styles;
}

</style>
