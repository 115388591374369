import { z, type ZodError } from 'zod'

const errorSchema = z.object({
  errors: z.array(
    z.object({
      title: z.string(),
      source: z.object({ pointer: z.string() })
    })
  )
})

export async function validatePaginatedResponse<TSchema extends z.Schema>(fn: Promise<Response>, schema: TSchema) {
  return await _validateResponseImpl(fn, z.object({
    data: z.array(schema),
    meta: z.object({
      page: z.number(),
      pageSize: z.number(),
      totalRecords: z.number()
    })
  }))
}

export async function validateResponse<TSchema extends z.Schema>(fn: Promise<Response>, schema: TSchema): Promise<z.infer<TSchema>> {
  const result = await _validateResponseImpl(fn, schema)

  return result
}

async function _validateResponseImpl<TSchema extends z.Schema>(fn: Promise<Response>, schema: TSchema) {
  const response = await fn;

  // Handle failed request.
  if (!response.ok) {
    const json = await response.json()
    const result = await errorSchema.safeParseAsync(json)

    if (result.success) {
      throw new ValidationError(
        response,
        result.data.errors.map(
          x => ({ pointer: x.source.pointer, message: x.title })
        )
      )
    } else {
      throw new ResponseError(response)
    }
  }

  const json = await response.json()
  const result = await schema.safeParseAsync(json)

  if (!result.success) {
    throw new SchemaError(response, result.error)
  }

  return result.data
}

export class ResponseError extends Error
{
  constructor(
    public readonly response: Response,
    message = 'Response failed.',
  ) {
    super(message)

    this.name = 'ResponseError'
  }
}

export class ValidationError extends ResponseError
{
  constructor(
    response: Response,
    public readonly errors: { pointer: string, message: string }[],
    message = 'Validation error encountered.'
  ) {
    super(response, message)

    this.name = 'ValidationError'
  }
}

export class SchemaError extends ResponseError
{
  constructor(
    response: Response,
    public readonly error: ZodError,
    message = 'Schema assertion failed'
  ) {
    super(response, message)

    this.name = 'SchemaAssertionError'
  }
}
