import { queryOptions } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { fetchAssetYields } from '@/features/assets/api/fetchAssetYields'

export const yieldQueries = {
  all: () => queryOptions({ queryKey: ['assets:yields'] }),

  fetch: (assetCode: MaybeRefOrGetter<string>) => queryOptions({
    queryKey: [...yieldQueries.all().queryKey, assetCode],
    queryFn: () => fetchAssetYields(toValue(assetCode)),
    meta: {
      logger: 'features/assets/yields.fetch'
    }
  })
} as const
