import { createRouter, createWebHistory } from 'vue-router'
import assetsRoutes from '@/features/assets/routes'
import projectsRoutes from '@/features/projects/routes'
import invoicesRoutes from '@/features/invoices/routes'
import authRoutes from '@/features/auth/routes'
import requireAuth from '@/shared/guards/requireAuth'
import setPageTitle from '@/shared/guards/setPageTitle'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  strict: false,
  routes: [
    {
      path: '/',
      redirect: { name: 'projects.list' }
    },

    ...authRoutes,
    ...assetsRoutes,
    ...projectsRoutes,
    ...invoicesRoutes,
  ]
})

router.beforeEach(requireAuth)
router.beforeEach(setPageTitle)

export default router

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean,
    pageTitle?: string,
  }
}
