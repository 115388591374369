import {
  Type,
  buildApiUrl,
  sendGetRequest,
  sendPatchRequest,
  sendPutRequest
} from '@/api/api'
import { assertSchema, assertSchemaAndReturn } from '@/helpers/assert-schema'
import { ProjectDetailsSchema, ProjectSchema } from '@/api/assets/schemas'

type project = {
    id: number,
    organisationId: number,
    projectCode: number,
    customerExternalIdentifier: string,
    poNumber: string,
    utilityProviderId: number,
    name: string,
    description: string,
    addressLineOne: string,
    addressLineTwo: string,
    suburb: string,
    city: string,
    countryId: string,
    vatNo: string
}

export async function updateProject(projectCode: number, project : project) {
    const url = buildApiUrl(Type.AssetManagement, `projects/${projectCode}`)
    await sendPutRequest(url, project)
}

export async function getProjectByAssetCode(assetCode: string) {

    const url = buildApiUrl(Type.AssetManagement, `project/asset/${assetCode}/includedetails=false`)
    const { data } = await sendGetRequest(url)

    assertSchema(data, ProjectSchema)

    return data
}

export async function updatePoNumber(projectPoNumber: {projectCode: number, poNumber: string}){

    const url = buildApiUrl(Type.AssetManagement, `project/${projectPoNumber.projectCode}/po-number`)

    await sendPatchRequest(url, { data: projectPoNumber})
}

export const getProjectDetailsByAssetCode = async (assetCode: string) =>
  sendGetRequest(
    buildApiUrl(Type.AssetManagement, `v2/assets/${assetCode}/projects`)
  ).then(
    ({ data }) => assertSchemaAndReturn(data, ProjectDetailsSchema)
  )

export const getProjectDetailsByProjectCode = async (projectCode: number) =>
  sendGetRequest(
    buildApiUrl(Type.AssetManagement, `v2/projects/${projectCode}`)
  ).then(
    ({ data }) => assertSchemaAndReturn(data, ProjectDetailsSchema)
  )
