import { computed, ref } from 'vue'

type ModalState = 'visible' | 'hidden'

export function useModalState(options: { initialState?: ModalState } = { }) {
  const { initialState = 'hidden' } = options

  const state = ref(initialState)

  return {
    isVisible: computed(() => state.value === 'visible'),
    isHidden: computed(() => state.value === 'hidden'),

    show: () => {
      state.value = 'visible'
    },

    hide: () => {
      state.value = 'hidden'
    }
  }
}
