import { toValue } from 'vue'
import type { Projects } from '@/types'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import transferProject from '@/api/projects/transfer-project'
import rejectProjectTransfer from '@/api/projects/reject-project-transfer'
import acceptProjectTransfer from '@/api/projects/accept-project-transfer'

type HookArgument = {
  type: Projects.TransferType
  projectId: number
}

type MutationArgument = {
  userId: string
}

export default function useProjectTransfers({ type, projectId } : HookArgument) {
  const queryClient = useQueryClient()

  return {
    createTransfer: useMutation({
      mutationFn: ({ userId } : MutationArgument) => transferProject({
        type,
        projectId,
        toUserId: userId
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects:list'] }).catch(() => {})
        queryClient.invalidateQueries({ queryKey: ['projects:details', projectId] }).catch(() => {})
      }
    }),

    acceptTransfer: useMutation({
      mutationFn: () => acceptProjectTransfer({ type: toValue(type), projectId: toValue(projectId) }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects:list'] }).catch(() => { })
        queryClient.invalidateQueries({ queryKey: ['projects:details', projectId] }).catch(() => {})
      }
    }),

    rejectTransfer: useMutation({
      mutationFn: () => rejectProjectTransfer({
        type,
        projectId: toValue(projectId),
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects:list'] }).catch(() => { })
      }
    }),
  }
}
