import { buildApiUrl, sendGetRequest, Type } from '@/api/api'
import { assertSchemaAndReturn } from '@/helpers/assert-schema'
import { z } from 'zod'
import * as validation from '@/helpers/validation'

function isAdditionalCharge(chargeId: number) {
  return chargeId > 6;
}

export async function getUtilities(countryId: number|undefined = undefined) {
  const url = buildApiUrl(Type.Core, 'utilities')

  if (countryId) {
    url.searchParams.set('countryId', countryId.toString())
  }

  return assertSchemaAndReturn(
    await sendGetRequest(url),
    z.object({
      data: z.array(
        z.object({
          id: z.number().int().gt(0),
          name: z.string()
        })
      )
    })
  ).data
}

export async function getTariffsByUtilityId(utilityId: number) {
  const data = assertSchemaAndReturn(
    await sendGetRequest(buildApiUrl(Type.Core, `utilities/${utilityId}/tariffs`)),
    z.object({
      data: z.array(
        z.object({
          SiteUtilityProviderStructureId: validation.id(),
          SiteUtilityProviderStructureType: z.object({
            SiteUtilityProviderStructureTypeDescription: z.string()
          }),
          YearOfTariff: z.number().int(),
          IsTimeOfUse: z.boolean(),
          SiteUtilityProviderBands: z.array(
            z.object({
              SiteUtilityProviderBandId: validation.id(),
              SiteUtilityProviderBandDescription: z.object({
                SiteUtilityProviderBandDescriptionId: validation.id(),
                SiteUtilityProviderBandName: z.string()
              }),
              SiteUtilityProvidersTariffTimeOfUse: z.object({
                Id: validation.id(),
                Name: z.string(),
              }).nullable(),
              SiteUtilityProviderSeason: z.object({
                SiteUtilityProviderSeasonsId: validation.id(),
                SiteUtilityProviderSeasonsDescription: z.string(),
              }),
              StartOfBand: z.number(),
              EndOfBand: z.number(),
              BandRate: z.number(),
            })
          )
        })
      )
    })
  ).data

  return data.map(
    tariff => ({
      id: tariff.SiteUtilityProviderStructureId,
      name: tariff.SiteUtilityProviderStructureType.SiteUtilityProviderStructureTypeDescription,
      isTimeOfUse: tariff.IsTimeOfUse,
      year: tariff.YearOfTariff,
      additionalCharges: tariff.SiteUtilityProviderBands
        .filter(x => isAdditionalCharge(x.SiteUtilityProviderBandDescription.SiteUtilityProviderBandDescriptionId))
        .map(
          band => ({
            id: band.SiteUtilityProviderBandId,
            name: band.SiteUtilityProviderBandDescription.SiteUtilityProviderBandName,
            rateInCents: band.BandRate,
            seasonId: band.SiteUtilityProviderSeason.SiteUtilityProviderSeasonsId,
            seasonName: band.SiteUtilityProviderSeason.SiteUtilityProviderSeasonsDescription,
          })
        ),
      bands: tariff.SiteUtilityProviderBands
        .filter(x => !isAdditionalCharge(x.SiteUtilityProviderBandDescription.SiteUtilityProviderBandDescriptionId))
        .map(
          band => ({
            id: band.SiteUtilityProviderBandId,
            name: tariff.IsTimeOfUse
              ? band.SiteUtilityProvidersTariffTimeOfUse?.Name ?? 'Unknown'
              : band.SiteUtilityProviderBandDescription.SiteUtilityProviderBandName,
            seasonId: band.SiteUtilityProviderSeason.SiteUtilityProviderSeasonsId,
            seasonName: band.SiteUtilityProviderSeason.SiteUtilityProviderSeasonsDescription,
            rangeStart: band.StartOfBand,
            rangeEnd: band.EndOfBand,
            rateInCents: band.BandRate
          })
        )
    })
  )
}
