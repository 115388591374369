import { useQuery } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import getOrganisationDetails, {
  type OrganisationDetails
} from '@/api/organisations/get-organisation-details'

export type { OrganisationDetails }

export default function useOrganisationDetails(id: MaybeRefOrGetter<number|undefined>) {
  return useQuery({
    queryKey: ['organisations:details', id],
    enabled: () => toValue(id) !== undefined,
    queryFn: () => getOrganisationDetails(toValue(id)!)
  })
}
