<script setup lang="ts">
import ModalBase from "@/components/modal/modal-container.vue";
import ModalTitle from "@/components/modal/modal-title.vue";
import ModalContent from "@/components/modal/modal-content.vue";
import { faArrowRight, faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import ModalFooterConfirmation from "@/components/modal/modal-footer-confirmation.vue";
import { useCancelInvoice } from "@/composables/billing/invoices";
import useNotifications from "@/composables/notifications";
import { computed } from "vue";
import { InvoiceStatus } from "@/api/billing/constants";

const props = defineProps<{
  invoiceId: number
  projectCode: number
  projectName: string
  status: InvoiceStatus
}>()
const emit = defineEmits<{ close: [] }>()

const { mutateAsync, isPending, isError } = useCancelInvoice()
const { addError, addSuccess } = useNotifications()
const invoiceDisplayName = computed(
  () => `${props.projectName} (${props.projectCode})`
)

async function cancelInvoice() {
  try {
    await mutateAsync(props.invoiceId)

    addSuccess({
      title: 'Invoice Cancelled Successfully',
      allowHtml: true,
      message: `Invoice <b>${props.invoiceId}</b> for <b>${invoiceDisplayName.value}</b> has been successfully cancelled.`
    })

    emit('close')
  } catch (e: unknown) {
    addError({
      title: 'Invoice Not Cancelled',
      allowHtml: true,
      message: `Invoice <b>${props.invoiceId}</b> for <b>${invoiceDisplayName.value}</b> could not be cancelled due to an error that occurred. Please try again.`
    })
  }
}
</script>

<template>
  <ModalBase @close="$emit('close')">
    <ModalTitle>
      Cancel Invoice Confirmation
    </ModalTitle>
    <ModalContent :icon="faExclamationTriangle" icon-variant="warning">
      <template v-if="isError">
        <p>The request to cancel Invoice <b>{{ invoiceId }}</b> for <b>{{ invoiceDisplayName }}</b> was unsuccessful.</p>
        <p>Would you like to try again?</p>
      </template>
      <template v-else>
        <p>
          Are you sure you would like to cancel Invoice <b>{{ invoiceId }}</b> for <b>{{ invoiceDisplayName }})</b>?
        </p>
        <p v-if="status === InvoiceStatus.Sent">
          This invoice was already sent. If you choose to proceed, it will be voided.
        </p>
      </template>
    </ModalContent>
    <ModalFooterConfirmation
      @reject="$emit('close')"
      @confirm="cancelInvoice()"
      :pending="isPending"
      :confirm-icon="status === InvoiceStatus.Sent ? faArrowRight : undefined"
      :confirm-text="status === InvoiceStatus.Sent ? 'Proceed' : undefined"
    />
  </ModalBase>
</template>
