import { buildApiUrl, sendDeleteRequest, sendPostRequest, sendPutRequest, Type } from "@/api/api";
import { assertSchema } from '@/helpers/assert-schema'
import { ContactsSchema, type Contact } from "@/api/contacts/schemas";

export async function addContact(assetCode: string, contact: Contact) {
    const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/contacts`);
    const { data } = await sendPostRequest(url, { data: contact });
    assertSchema(data, ContactsSchema);
    return data
}
export async function deleteContact(contactId: number) {
    const url = buildApiUrl(Type.AssetManagement, `contacts/${contactId}`);
    const { data } = await sendDeleteRequest(url);
    return data
}
export async function updateContact(contact: Contact) {
    const url = buildApiUrl(Type.AssetManagement, `contacts/${contact.id}`);
    const { data } = await sendPutRequest(url, { data: contact });
    assertSchema(data, ContactsSchema);
    return data
}
export async function updateContacts(assetCode: string, contacts: Contact[]) {
    const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/contacts`);
    await sendPutRequest(url, { data: contacts })
}
