import type { RouteRecordRaw } from 'vue-router'
import BillingInvoicingDashboard from '@/views/billing/invoicing/dashboard.vue'
import TaxInvoice from '@/views/billing/invoicing/taxInvoice.vue'

export default [
  { path: '/billing/invoices',
    redirect: { name: 'invoices.list' }
  },
  { path: '/invoices',
    component: BillingInvoicingDashboard,
    name: 'invoices.list',
    meta: {
      pageTitle: 'Asset Invoices',
    }
  },
  { path: '/invoices/:invoiceId',
    name: 'invoices.update',
    component: TaxInvoice
  }
] satisfies RouteRecordRaw[] as RouteRecordRaw[]
