<script setup lang="ts">
import { useCreateBillingCharge, useGetBillingCharge, useUpdateBillingCharge } from '@/composables/billing/invoices'
import { computed, ref,toRef } from 'vue'
import { z } from 'zod'
import InputText from '@/components/input-text.vue'
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import InputLabel from '@/components/input-label.vue'
import InputNumber from '@/components/input-number.vue'
import { roundToFractionalCurrencyPrecision } from '@/helpers'
import useNotifications from '@/composables/notifications'
import { formatToMainCurrency, formatToFractionalCurrency } from '@/helpers/formatting'
import useLoader from '@/composables/loader'
import ModalVariantForm from '@/components/modal/modal-variant-form.vue'
import InputDate from '@/components/input-date.vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import ContentLoader from '@/components/content-loader.vue'

const props = defineProps<{ billingChargeId:number, assetCode: string }>()
const emit = defineEmits<{ close: [] }>()

const isEdit = computed(() => props.billingChargeId !== 0)

const { addSuccess, addError } = useNotifications()
const { wrap: wrapWithLoader } = useLoader()
const { mutateAsync: createBillingCharge } = useCreateBillingCharge(toRef(props, 'assetCode'))
const { mutateAsync: updateBillingCharge } = useUpdateBillingCharge(toRef(props, 'billingChargeId'), toRef(props, 'assetCode'))
const { data: billingChargeData, isLoading: loadingBillingCharge} = useGetBillingCharge(toRef(props, 'billingChargeId'))

const validationSchema = z.object({
  description: z.string().min(1),
  rateInMainCurrency: z.number().gt(0),
  annualEscalationPercentage: z.number().gte(0),
  startDate: z.date(),
  endDate: z.date().nullable(),
  nextEscalationDate: z.date().nullable()
})

const billingCharge = computed(() => {
  return isEdit.value ? billingChargeData.value : undefined
})

function performSave(values: z.infer<typeof validationSchema>) {

  wrapWithLoader(
    () => !isEdit.value ? createBillingCharge({
      description: values.description,
      rateInFractionalCurrency: roundToFractionalCurrencyPrecision(values.rateInMainCurrency * 100),
      quantity: 1,
      annualEscalationPercentage: values.annualEscalationPercentage,
      startDate: values.startDate,
      endDate: values.endDate,
      nextEscalationDate: values.nextEscalationDate
    }) : updateBillingCharge({
      id: billingCharge.value!.id,
      description: values.description,
      rateInCents: roundToFractionalCurrencyPrecision(values.rateInMainCurrency * 100),
      quantity: 1,
      annualEscalationPercentage: values.annualEscalationPercentage,
      startDate: values.startDate,
      endDate: values.endDate,
      nextEscalationDate: values.nextEscalationDate
    })
  ).then(
    () => {
      var title = !isEdit.value ? 'Recurring charge created successfully.' : 'Recurring charge updated successfully.'
      var message = !isEdit.value ? `Your new recurring charge <b>${values.description}</b> for <b>${formatToMainCurrency(values.rateInMainCurrency)}</b> has been created successfully, and will now be present in invoices generated using this template.`:
                     `Your recurring charge <b>${values.description}</b> for <b>${formatToMainCurrency(values.rateInMainCurrency)}</b> has been updated successfully, and will now be present in invoices generated using this template.`
      addSuccess({
        title: title,
        allowHtml: true,
        message: message
      })

      emit('close')
    },
    () => addError({
      title: 'Recurring charge not created.',
      message: 'We were unable to create your new recurring charge. Please try again.'
    })
  )
}

</script>

<template>
  <ModalVariantForm :width="393" :title="!isEdit ? `New Charge` : `Charge`" @cancel="$emit('close')" @submit="performSave" :schema="validationSchema" #default="{ currentValues }">
    <ContentLoader :loading="loadingBillingCharge"></ContentLoader>
    <GridContainer variant="inputs" v-if="isEdit ? !loadingBillingCharge : true">

      <GridItem>
        <InputLabel class="text-nowrap">Charge Description</InputLabel>
      </GridItem>
      <GridItem>
        <InputText name="description" required error-variant="tooltip" :value="billingCharge?.description"/>
      </GridItem>

      <GridItem>
          <InputLabel class="text-nowrap">Start Date
            <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle"
            v-tooltip="`The date from which the contract or the agreement term starts`">
            </FontAwesomeIcon>
          </InputLabel>
      </GridItem>
      <GridItem>
        <div class="w-75">
            <InputDate name="startDate" required error-variant="tooltip" :value="billingCharge?.startDate" :max="currentValues?.endDate ?? undefined"/>
        </div>
      </GridItem>

      <GridItem>
        <InputLabel class="text-nowrap">First Escalation</InputLabel>
      </GridItem>
      <GridItem>
        <div class="w-75">
        <InputDate name="nextEscalationDate" :value="billingCharge?.nextEscalationDate" :min="currentValues?.startDate" :max="currentValues?.endDate ?? undefined"/>
        </div>
      </GridItem>

      <GridItem>
        <InputLabel class="text-nowrap">End Date
        <FontAwesomeIcon class="tooltip-color ms-1" :icon="faQuestionCircle"
        v-tooltip="`The date on which the contract or the agreement term ends`"></FontAwesomeIcon>
        </InputLabel>
      </GridItem>
      <GridItem>
        <div class="w-75">
          <InputDate name="endDate" :value="billingCharge?.endDate" :min="currentValues?.startDate"/>
        </div>
      </GridItem>

      <GridItem>
        <InputLabel>Rate</InputLabel>
      </GridItem>
      <GridItem>
        <div class="w-50">
          <InputNumber name="rateInMainCurrency" required error-variant="tooltip" :value="(billingCharge?.rateInCents ?? 0) / 100">
            <template #prefix><span class="text-muted">R</span></template>
          </InputNumber>
        </div>
      </GridItem>

      <GridItem>
        <InputLabel class="text-nowrap">Annual Escalation</InputLabel>
      </GridItem>
      <GridItem>
        <div class="w-50">
          <InputNumber name="annualEscalationPercentage" required error-variant="tooltip" :value="billingCharge?.annualEscalationPercentage">
            <template #suffix><span class="text-muted">%</span></template>
          </InputNumber>
        </div>
      </GridItem>

    </GridContainer>
  </ModalVariantForm>
</template>

<style module lang="scss">

</style>
