<script setup lang="ts">

import ModalVariantForm from '@/components/modal/modal-variant-form.vue'
import { z } from 'zod'
import InputLabel from '@/components/input-label.vue'
import TooltipIcon from '@/shared/components/TooltipIcon.vue'
import InputDate from '@/components/input-date.vue'
import { useYieldUpdate } from '@/features/assets/mutations'
import useNotifications from '@/composables/notifications'
import GridContainer from '@/components/grid-container.vue'

const emit = defineEmits<{ close: [] }>()
const props = defineProps<{ assetCode: string }>()
const validationSchema = z.object({ date: z.date({ message: 'A date is required.' }) })
const { mutateAsync, isPending } = useYieldUpdate(() => props.assetCode)
const { addError, addSuccess } = useNotifications()

async function saveForm(values: z.infer<typeof validationSchema>) {
  try {
    await mutateAsync(values.date)

    addSuccess({ title: 'Guarantee end date updated successfully.' })

    emit('close')
  } catch (ex) {
    console.log('got error', ex)
    addError({ title: 'Failed to update guarantee end date' })
  }
}

</script>

<template>
  <ModalVariantForm :pending="isPending" @submit="saveForm" @close="$emit('close')" :schema="validationSchema" title="Guarantee Actual End Date">
    <GridContainer :columns="['max-content', 'auto']" class="align-items-center">
      <InputLabel>
        Guarantee Actual End Date
        <TooltipIcon clas="ms-1" text="The confirmed date when the asset exits the guarantee period and transitions to the warranty period." />
      </InputLabel>
      <InputDate name="date" />
    </GridContainer>
  </ModalVariantForm>
</template>
