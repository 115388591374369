export const BaseUrls = {
  CoreApi: new URL(import.meta.env.VITE_API_BASE_URL),
  Core: new URL(import.meta.env.VITE_CORE_URL),
  AssetManagement: new URL(import.meta.env.VITE_ASSET_MANAGEMENT_API_URL),
} as const

export const UrlPaths = {
  AssetManagement: {
    Dashboard: 'Operations/AssetManagementDashboard',
  } as const
} as const
