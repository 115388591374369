<script setup lang="ts" generic="TSchema extends z.Schema">
import {
  faFileCheck,
  faFileLines,
  faTrash, faTriangleExclamation,
  faUpload,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'
import ButtonSecondary from '@/components/button-secondary.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useModalState } from '@/shared/composables/useModalState'
import { useTemplateRef, ref, computed, onMounted } from 'vue'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import ModalFooter from '@/components/modal/modal-footer.vue'
import ButtonPrimary from '@/components/button-primary.vue'
import useNotifications from '@/composables/notifications'
import { z, ZodError } from 'zod'
import { useFileUploads } from '@/shared/composables/useFileUploads'
import { buildAssetManagementUrl } from '@/shared/utils/urls/builders'
import ProgressBar from '@/shared/components/ProgressBar.vue'
import { ValidationError } from '@/shared/api/validation'

defineOptions({ inheritAttrs: false })

const props = defineProps<{
  max?: number
  url: string
  title?: string
  schema: TSchema
  allowedExtensions?: string | string[]
}>()

const emit = defineEmits<{
  'file:failed': [error: unknown, file: File]
  'file:uploaded': [file: File]
  'file:added': [file: File]

  complete: [data: {
    uploaded: z.infer<TSchema>[]
    failed: unknown[]
    close: () => void
  }],
}>()

const fileSizeFormatter = Intl.NumberFormat("en", {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
})

const { addError } = useNotifications()
const fileInput = useTemplateRef('fileInput')
const isDraggingOver = ref(false)
const { show: showUploadModal, hide: hideUploadModal, isVisible: isUploadModalVisible } = useModalState()
const pluralise = (count: number, singular: string, plural: string) => count === 1 ? singular : plural
const uploadErrors = ref<{ file: File, message: string }[]>([])

const { files, addFile, removeFile, upload, isUploading, onFileError, onFileUploaded } = useFileUploads({
  url: buildAssetManagementUrl('uploads'),
  schema: computed(() => props.schema),
})


onFileUploaded(({ file }) => emit('file:uploaded', file))

onFileError(
  ({ file, error }) => {
    emit('file:failed', error, file)

    if (error instanceof ValidationError && error.errors.length > 0) {
      uploadErrors.value.push({ file, message: error.errors[0].message })
    }
  }
)

const acceptAttribute = computed(
  () => props.allowedExtensions === undefined
    ? undefined
    : (
      Array.isArray(props.allowedExtensions)
        ? props.allowedExtensions.map(x => `.${x.replace(/^\.+/, '')}`).join(',')
        : props.allowedExtensions
    )
)

function startUploading() {
  uploadErrors.value = []

  upload().then(
    result => {
      emit('complete', {
        uploaded: result.uploaded,
        failed: result.failed,
        close: () => hideUploadModal()
      })
    }
  )
}

function tryAddFiles(filesToAdd: File[]) {
  const newLength = filesToAdd.length + files.value.length

  if (props.max !== undefined && newLength > props.max) {
    addError({
      title: 'Unable to add more files.',
      message: `Only ${props.max} ${pluralise(props.max, 'file', 'files')} allowed. Adding ${pluralise(filesToAdd.length, 'this', 'these')} would bring the total to ${newLength}.`,
    })

    return false
  }

  filesToAdd.forEach(
    file => {
      addFile(file)
      emit('file:added', file)
    }
  )

  return true
}

function addFilesFromDrop(event: DragEvent) {
  event.preventDefault()

  let extracted: File[] = []

  if (event.dataTransfer?.items) {
    extracted.push(
      ...[...event.dataTransfer.items]
        .map(x => x.getAsFile())
        .filter(x => x !== null)
    )
  } else {
    extracted.push(...(event.dataTransfer?.files || []))
  }

  tryAddFiles(extracted)

  isDraggingOver.value = false
}

function addFilesFromInput(event: Event) {
  const target = event.target

  if (! (target instanceof HTMLInputElement)) {
    return
  }

  tryAddFiles([...(target.files || [])])
}

function removeFileAtIndex(index: number) {
  if (!isUploading.value) {
    removeFile(index)
  }
}
</script>

<template>
  <input type="file" style="display: none" :accept="acceptAttribute" :multiple="max !== undefined && max > 1" ref="fileInput" @change="addFilesFromInput" />

  <ButtonSecondary v-bind="$attrs" :rounded="false" :shadow="false" required :class="$style['file-upload']" @click="showUploadModal()">
    <FontAwesomeIcon :icon="faUpload" class="me-1" /> Upload
  </ButtonSecondary>

  <Teleport to="body">
    <ModalContainer v-if="isUploadModalVisible" @close="hideUploadModal">
      <ModalTitle>{{ title ?? 'Upload file' }}</ModalTitle>
      <ModalContent>
        <div :class="[$style['drag-area'], { [$style['dragging-over']]: isDraggingOver }]" class="text-center" @drop.prevent="addFilesFromDrop" @dragover.prevent="isDraggingOver = true" @dragleave.prevent="isDraggingOver = false">
          <FontAwesomeIcon :class="$style.icon" :icon="faFileLines" />
          <div class="mt-2">Drag your document here to start uploading</div>
          <div :class="$style['or']" class="mt-2">or</div>
          <ButtonSecondary class="mt-2" :shadow="false" :rounded="false" :icon="faFileCheck" @click="fileInput?.click()">Select File</ButtonSecondary>
        </div>
        <template v-if="files.length > 0">
          <template v-for="(item, index) in files" :key="`${index}|${item.file.name}|${item.file.lastModified}`">
            <div :class="{ [$style.file]: true, [$style.error]: item.state === 'failed' }" class="ps-2 pe-2 pt-1 pb-1 mt-2 d-flex justify-content-between align-items-center">
              <div>
                {{ item.file.name }} <span class="text-muted ms-2">({{ fileSizeFormatter.format(item.file.size) }})</span>
              </div>
              <div class="d-flex align-items-center">
                <FontAwesomeIcon v-if="item.state === 'failed'"
                                 :icon="faTriangleExclamation"
                                 class="me-2 text-danger"
                                 v-tooltip="{
                                   color: 'red',
                                   text: uploadErrors.find(x => x.file === item.file)?.message || `Encountered an error trying to upload this file.`
                                 }" />
                <span class="d-inline-block" :class="[$style.delete, { [$style.uploading]: isUploading }]" @click="removeFileAtIndex(index)">
                  <FontAwesomeIcon :icon="faTrash" />
                </span>
              </div>
            </div>
            <ProgressBar v-if="item.state === 'uploading' || item.state === 'uploaded'" class="w-100" :height="2" :value="item.progress" />
          </template>
        </template>
      </ModalContent>
      <ModalFooter class="text-end">
        <ButtonSecondary @click="hideUploadModal" :icon="faXmark">Close</ButtonSecondary>
        <ButtonPrimary :disabled="files.length < 1 || isUploading" class="ms-2" :icon="faUpload" @click="startUploading()">Upload</ButtonPrimary>
      </ModalFooter>
    </ModalContainer>
  </Teleport>
</template>

<style module lang="scss">
@import "@/assets/variables";
.progress {
  border-radius: 0;
}

.file {
  background: #eee;

  &.error {
    background: #{rgba($red, 0.075)};
    border-style: solid;
    border-color: #{rgba($red, 0.75)};
    border-width: 2px 0 0 0;
  }

  .delete {
    cursor: pointer;

    &.uploading {
      cursor: not-allowed;
    }

    &:hover {
      color: darken($body-color, 20%);
    }
  }
}

.drag-area {
  background: #eeeeee;
  border: 1px dashed #d1d1d1;
  padding: 21px;
  border-radius: 7px;

  &.dragging-over {
    border-color: darken($yellow, 20%);
    background-color: rgba($yellow, 0.12);
  }

  .or {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;

    &::before, &::after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid #DDDDDD;
    }
  }

  .icon {
    color: $cyan;
    font-size: 42px;
  }
}

.file-upload {
  color: #B2B2B2;
}
</style>
