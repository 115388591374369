<script setup lang="ts">
import { computed, useAttrs, useSlots } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import { isSlotEmpty } from '@/helpers/components'
import { type BaseButtonProps, BaseButtonDefaults } from './_constants'

const attrs = useAttrs()
const slots = useSlots()
const props = withDefaults(
  defineProps<BaseButtonProps>(),
  { ...BaseButtonDefaults }
)

const disabledComputed = computed(() => props.preset === 'loading' ? true : props.disabled)
const isShowingIcon = computed<boolean>(() => iconProps.value !== undefined)
const isIconOnly = computed<boolean>(() => isShowingIcon.value && isSlotEmpty(slots.default))

const iconProps = computed(() => {
  if (props.preset === 'loading') {
    return { spin: true, icon: faSpinnerThird }
  }

  const icon = props.icon
  const animation = props.animation

  if (icon === undefined) {
    return undefined
  }

  if (animation !== undefined) {
    return { [animation]: true, icon }
  }

  return { icon }
})
</script>

<template>
  <button
    type="button"
    v-bind="attrs"
    :disabled="disabledComputed"
    class="btn"
    :class="{
      'rounded-pill': rounded,
      'btn-shadow': shadow,
      'btn-sm': size === 'small',
      'btn-lg': size === 'large',
      'text-nowrap': isShowingIcon,
      'btn-required': required,
    }">
    <FontAwesomeIcon
      v-if="isShowingIcon && iconPosition === 'before'"
      v-bind="iconProps!"
      :class="{ 'me-1': !isIconOnly }" />
    <slot />
    <FontAwesomeIcon
      v-if="isShowingIcon && iconPosition === 'after'"
      v-bind="iconProps!"
      :class="{ 'ms-1': !isIconOnly }" />
  </button>
</template>
