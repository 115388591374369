import { assertSchemaAndCoerce } from '@/shared/utils/schemas/assertions'
import { z } from 'zod'
import { randomDelayBetween } from '@/helpers'

let currentStep: z.infer<typeof schema> = {
  assetCode: '',
  state: 'initial',
  guaranteeEndDate: null,
  approvals: {
    required: 0,
    received: []
  }
}

export function setEndDate(value: Date) {
  currentStep.state = 'guarantee:approving'
  currentStep.guaranteeEndDate = value
  currentStep.approvals = {
    required: 1,
    received: []
  }
}

export function reset() {
  currentStep = {
    ...currentStep,
    state: 'initial',
    guaranteeEndDate: null,
    approvals: {
      required: 0,
      received: []
    }
  }
}

export function start() {
  currentStep = {
    ...currentStep,
    state: 'initial:approving',
    approvals: {
      required: 4,
      received: []
    }
  }
}

export function reject() {
  if (currentStep.state === 'initial:approving') {
    currentStep = { ...currentStep, state: 'initial:rejected' }
  } else if (currentStep.state === 'guarantee:approving') {
    currentStep = { ...currentStep, state: 'guarantee:rejected' }
  }
}

export function approve() {
  switch (currentStep.state) {
    case 'initial:approving':
      currentStep.approvals.received.push({ userId: '', approvedAt: new Date() })

      if (currentStep.approvals.received.length === currentStep.approvals.required) {
        currentStep.state = 'guarantee:approved'
        currentStep.approvals = { required: 0, received: [] }
      }
      break;

    case 'guarantee:approved':
      currentStep = {
        ...currentStep,
        state: 'guarantee:approving',
        approvals: {
          required: 1,
          received: []
        }
      }
      break;

    case 'guarantee:approving':
      currentStep = { ...currentStep, state: 'warranty:approved', approvals: { required: 0, received: [] } }
      break;
  }
}

const schema = z.object({
  assetCode: z.string(),
  guaranteeEndDate: z.coerce.date().nullable(),
  state: z.union([
    z.literal('initial'),
    z.literal('initial:rejected'),
    z.literal('initial:approving'),
    z.literal('guarantee:rejected'),
    z.literal('guarantee:approved'),
    z.literal('guarantee:approving'),
    z.literal('warranty:approved'),
  ]),
  approvals: z.object({
    required: z.number(),
    received: z.array(
      z.object({
        userId: z.string(),
        approvedAt: z.date({ coerce: true })
      })
    )
  })
})

export async function fetchAssetYields(assetCode: string) {
  await randomDelayBetween(500, 1000)

  return assertSchemaAndCoerce({ ...currentStep }, schema)
}
