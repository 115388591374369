<script setup lang="ts">
import { computed } from 'vue'
import useAuthenticatedUserDetails from '@/composables/users/authenticated-user-details'
import TopNavPrimary from "@/views/nav/top-nav-primary.vue";
import TopNavSecondary from "@/views/nav/top-nav-secondary.vue";
import { useRoute } from "vue-router";

const props = defineProps<{ showSecondary?: boolean }>()
const route = useRoute()
const { data: authenticatedUser } = useAuthenticatedUserDetails()

const showSecondary = computed(() => route.name !== 'auth.login' && authenticatedUser.value !== undefined && props.showSecondary)
</script>

<template>
  <div :class="$style.container">
    <TopNavPrimary />
    <TopNavSecondary v-if="showSecondary" />
  </div>
</template>

<style module lang="scss">
.container {
  z-index: 5;
  position: sticky;
  top: 0;
}
</style>
