import { buildApiUrl, sendGetRequest, Type } from "@/api/api";
import { assertSchema } from '@/helpers/assert-schema'
import {ContactsSchema } from "@/api/contacts/schemas";
import { z } from "zod";

export const schema = z.array(
    z.object({
        id: z.number().int().gt(0),
        name: z.string()
    })
)
export async function getContactTypes() {
    const url = buildApiUrl(Type.AssetManagement, 'contacts/contact-types');
    const { data } = await sendGetRequest(url);
    assertSchema(data, schema);
    return data;
}

export async function getContacts(assetCode: string, contactType: number) {
    const url = buildApiUrl(Type.AssetManagement, `assets/${assetCode}/contacts?contactTypeId=${contactType}`);
    const { data } = await sendGetRequest(url);
    assertSchema(data, z.array(ContactsSchema));
    return data
}
