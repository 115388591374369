import type { DirectiveBinding, ObjectDirective } from "vue";
import Tooltip from 'bootstrap/js/dist/tooltip'
import cssClasses from '@/assets/styles/directives/tooltip.module.scss'
import { createLogger } from '@/helpers/logging'

const elementToTooltipMap = new WeakMap<Element, Tooltip>()
const logger = createLogger('directives:tooltip')

type Colors = 'red'
type AcceptedValues = string | undefined | null | { text?: string, color?: Colors, placement?: Tooltip.PopoverPlacement, variant?: 'error' }

function getTooltip(el: Element) {
  return elementToTooltipMap.get(el)
}

function extractProperties(binding: DirectiveBinding<AcceptedValues>) {
  const defaults = {
    text: '',
    placement: 'top' as Tooltip.PopoverPlacement,
    color: undefined,
    variant: undefined,
  }

  const value = binding.value

  if (value === undefined || value === null) {
    return { ...defaults }
  }

  if (typeof value === 'string') {
    return { ...defaults, text: value.trim() }
  }

  return {
    ...defaults,
    ...value,
    text: (value.text || defaults.text).trim()
  }
}

function setPlacement(el: Element, binding: DirectiveBinding) {
  el.setAttribute('data-bs-placement', extractProperties(binding).placement)
}

function setText(el: Element, binding: DirectiveBinding<AcceptedValues>) {
  el.setAttribute('data-bs-title', extractProperties(binding).text.replace(/\n/g, '<br>'))
}

function setColor(el: Element, binding: DirectiveBinding<AcceptedValues>) {
  el.setAttribute('data-bs-color', extractProperties(binding).color || '')
}

function setVariant(el: Element, binding: DirectiveBinding<AcceptedValues>) {
  el.setAttribute('data-bs-variant', extractProperties(binding).variant || '')
}

function setTooltip(el: Element) {
  const tt = new Tooltip(el, {
    offset: [0, 10],
    html: true,
    trigger: 'hover',
    delay: {
      show: 100,
      hide: 150,
    },
    placement() {
      const variant = el.getAttribute('data-bs-variant')

      if (variant === 'error') {
        return 'bottom'
      }

      return (el.getAttribute('data-bs-placement') || 'top') as Tooltip.PopoverPlacement
    },
    title: () => el.getAttribute('data-bs-title') || '',
    customClass: () => {
      const variant = el.getAttribute('data-bs-variant')
      const classNames = [cssClasses.tooltip]

      if (variant === 'error') {
        return classNames.concat([cssClasses.colorRed]).join(' ')
      }

      const colorClass = `color-${el.getAttribute('data-bs-color')}`

      if (colorClass in cssClasses) {
        classNames.push(cssClasses[colorClass])
      }

      return classNames.join(' ')
    },
    popperConfig: {

    }
  })

  elementToTooltipMap.set(el, tt)

  el.addEventListener(
    'show.bs.tooltip',
    () => logger.debug('Showing tooltip for element.', { element: el })
  )

  el.addEventListener(
    'hide.bs.tooltip',
    () => logger.debug('Hiding tooltip for element.', { element: el })
  )

  el.addEventListener(
    'inserted.bs.tooltip',
    () => logger.debug('Inserted tooltip for element into DOM', { element: el })
  )

  return tt
}

/**
 * @todo Currently, if there's a link in the tooltip the user won't be able to
 *       click on it - the tooltip will hide. We need to manually show/hide the
 *       tooltip, and only hide it when leaving the element _or_ the tooltip.
 */

const directive: ObjectDirective<Element, AcceptedValues> = {
  beforeMount(el) {
    setTooltip(el)

    el.setAttribute('data-bs-toggle', 'tooltip')
  },
  mounted(el, binding) {
    setPlacement(el, binding)
    setColor(el, binding)
    setText(el, binding)
    setVariant(el, binding)
  },
  updated(el, binding) {
    setPlacement(el, binding)
    setColor(el, binding)
    setText(el, binding)
    setVariant(el, binding)

    getTooltip(el)?.setContent({ '.tooltip-inner': el.getAttribute('data-bs-title') })
  },
  beforeUnmount(el) {
    getTooltip(el)?.dispose()

    elementToTooltipMap.delete(el)
  }
}

export default directive
