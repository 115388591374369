import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { type MaybeRefOrGetter, toValue } from 'vue'
import closeProject from '@/api/projects/close-project'
import getProjectClosureReasons from "@/api/projects/get-project-closure-reasons";

type ComposableArgument = {
  projectId: number
}

type MutationArgument = {
  reasonId: MaybeRefOrGetter<number>
  text: MaybeRefOrGetter<string|undefined>
}

export function useProjectClosure({ projectId } : ComposableArgument) {
  const queryClient = useQueryClient()

  return {
    closeProject: useMutation({
      mutationFn: ({ reasonId, text } : MutationArgument) => closeProject({
        projectId,
        text: toValue(text),
        reasonId: toValue(reasonId)
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects:list'] }).catch(() => { })
      }
    }),

    closureReasons: useQuery({
      queryKey: ['projects:closureReasons'],
      queryFn: () => getProjectClosureReasons(),
      staleTime: Infinity
    })
  }
}
