import { yieldQueries } from '@/features/assets/queries'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { useMutationWithInvalidation } from '@/shared/composables/useMutationWithInvalidation'
import { approveYield } from '@/features/assets/api/approveYield'
import type { YieldType } from '@/features/assets/types'
import { rejectYield } from '@/features/assets/api/rejectYield'
import { updateYield } from '@/features/assets/api/updateYield'

export const useYieldApproval = (assetCode: MaybeRefOrGetter<string>) => useMutationWithInvalidation(
  (type: YieldType) => approveYield(toValue(assetCode), type),
  [yieldQueries.fetch(assetCode).queryKey]
)

export const useYieldRejection = (assetCode: MaybeRefOrGetter<string>) => useMutationWithInvalidation(
  (type: YieldType) => rejectYield(toValue(assetCode), type),
  [yieldQueries.fetch(assetCode).queryKey]
)

export const useYieldUpdate = (assetCode: MaybeRefOrGetter<string>) => useMutationWithInvalidation(
  (guaranteeEndDate: Date) => updateYield({ guaranteeEndDate }),
  [yieldQueries.fetch(assetCode).queryKey]
)
