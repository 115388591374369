import type { RouteRecordRaw } from 'vue-router'
import AssetLayout from '@/features/assets/components/AssetLayout.vue'
import AssetSetup from '@/views/assetSetup/setup.vue'
import TaxInvoiceTemplate from '@/views/billing/invoicing/taxInvoiceTemplate.vue'
import AssetMetersDashboard from '@/views/assets/meters/asset-meters-dashboard.vue'
import AssetYields from '@/features/assets/components/AssetYields.vue'

export default [
  {
    path: '/assets/:assetCode',
    component: AssetLayout,
    props: true,
    children: [
      { path: 'settings', name: 'assets.settings', component: AssetSetup, props: true },
      { path: 'invoicing/template', name: 'assets.setupInvoice', component: TaxInvoiceTemplate, props: true },
      { path: 'meters', name: 'assets.meters', component: AssetMetersDashboard, props: true },
      { path: 'yields', name: 'assets.yields', component: AssetYields, props: true },
    ]
  }
] satisfies RouteRecordRaw[] as RouteRecordRaw[]
