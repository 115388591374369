import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import authenticate from '@/api/authentication/authenticate'

type Argument = {
  username: MaybeRefOrGetter<string>
  password: MaybeRefOrGetter<string>
}

export default function useAuthentication() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ username, password }: Argument) => authenticate({
      username: toValue(username),
      password: toValue(password),
    }),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    }
  })
}
