import { type MaybeRefOrGetter, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { getTariffsByUtilityId } from '@/api/utilities/utilities'

export function useUtilityTariffs(utilityId: MaybeRefOrGetter<number|undefined>) {
  return useQuery({
    enabled: () => {
      const resolvedId = toValue(utilityId)

      return resolvedId !== undefined && resolvedId > 0
    },
    queryKey: ['utilities:tariffs', utilityId],
    queryFn: () => getTariffsByUtilityId(toValue(utilityId)!)
  })
}
