<script setup lang="ts">
import { useQueries } from '@tanstack/vue-query'
import { computed, ref } from 'vue'
import { uploadedFileQueries } from '@/shared/queries/uploads'
import {
  faCircleCheck,
  faCircleNotch,
  faDownload,
  faExclamationTriangle,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useFileUploadRemoval } from '@/shared/composables/useFileUploadRemoval'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

type PropState = 'ok' | 'processing' | 'error'
type UploadedFile = (
  { state: 'loading', id: string } |
  { state: 'loading:failed', id: string } |
  { state?: PropState | 'removing', id: string, name: string, downloadUrl: string }
)

const props = defineProps<{ uploads: (string|{ id: string, state?: PropState })[]  }>()
const emit = defineEmits<{
  delete: [id: string, deleteFn: () => Promise<void>]
}>()

const { mutateAsync: removeUploadedFile } = useFileUploadRemoval()
const currentlyRemoving = ref<string[]>([])

const fileQueries = useQueries({
  queries: computed(
    () => props.uploads.map(x => uploadedFileQueries.fetch(typeof x === 'string' ? x : x.id))
  )
})

const fileList = computed<UploadedFile[]>(
  () => props.uploads.map(
    (upload, index) => {
      const id = typeof upload === 'string' ? upload : upload.id
      const state = typeof upload === 'string' ? undefined : upload.state
      const query = fileQueries.value[index]

      // Either the query wasn't found, or there was an error with the query.
      if (query === undefined || query.isError) {
        return { id, state: 'loading:failed' }
      }

      // Busy fetching the details.
      if (query.isPending || query.isFetching) {
        return { id, state: 'loading' }
      }

      const { fileName, downloadUrl } = query.data

      return {
        id,
        state: currentlyRemoving.value.indexOf(id) > -1 ? 'removing' : state,
        name: fileName,
        downloadUrl
      }
    }
  )
)

function removeFile(id: string) {
  // Already removing it.
  if (currentlyRemoving.value.indexOf(id) > -1) {
    return
  }

  currentlyRemoving.value.push(id)

  emit(
    'delete',
    id,
    () => removeUploadedFile(id).finally(
      () => {
        const index = currentlyRemoving.value.indexOf(id)

        if (index > -1) {
          currentlyRemoving.value.splice(index, 1)
        }
      }
    )
  )
}

</script>

<template>
  <div>
    <div class="d-flex align-items-center w-100" v-for="file in fileList" :key="file.id">
      <div :class="[$style.item, { [$style.removing]: file.state === 'removing' }]" class="flex-grow-1 justify-content-between d-flex align-items-center">
        <em v-if="file.state === 'loading'">Loading...</em>

        <template v-else-if="file.state === 'loading:failed'">
          <em>Failed to load.</em>
          <FontAwesomeIcon :icon="faExclamationTriangle" class="text-danger ps-2" v-tooltip="`This details of this file couldn't be loaded.`" />
        </template>

        <template v-else>
          <span>{{ file.name }}</span>
          <span class="d-flex align-items-center gap-2">
            <a v-if="file.downloadUrl" target="_blank" :href="file.downloadUrl" class="d-block">
              <FontAwesomeIcon :icon="faDownload" />
            </a>
            <a href="javascript:void(0)" class="d-inline-block" @click="removeFile(file.id)">
              <FontAwesomeIcon :icon="file.state === 'removing' ? faSpinnerThird : faTrashCan" :spin="file.state === 'removing'" />
            </a>
          </span>
        </template>
      </div>

      <div class="ps-2" v-if="file.state === 'ok'">
        <FontAwesomeIcon :icon="faCircleCheck" class="text-success" />
      </div>

      <div class="ps-2" v-if="file.state === 'error'">
        <FontAwesomeIcon v-if="file.state === 'error'" :icon="faExclamationTriangle" class="text-danger" />
      </div>

      <div class="ps-2" v-if="file.state === 'processing'" v-tooltip="`Processing...\nYou'll be notified when done.`">
        <FontAwesomeIcon :icon="faCircleNotch" spin />
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.item {
  padding: 0 7px;
  background-color: #eee;

  a {
    color: #{$body-color};
  }

  &.removing {
    opacity: 0.5;
  }
}
</style>
