import makeApiClient, { Type } from '@/api/api'

type Argument = {
  projectId: number
  reasonId: number
  text?: string
}

const { sendPatchRequest } = makeApiClient(Type.Core)

export default async function closeProject({ projectId, reasonId, text }: Argument) {
  await sendPatchRequest(`projects/${projectId}/status`, { status: 'closed', reasonId, text })
}
