<script setup lang="ts">
import useAuthenticatedUserDetails from "@/composables/users/authenticated-user-details";
import HeroSplash from '@/components/hero-splash.vue'
import heroSplashImage from '@/assets/projects/dashboard/splash.svg'
import VerticallyCentered from "@/components/vertically-centered.vue";
import PageHeader from "@/components/page-header.vue";
import ButtonPrimary from "@/components/button-primary.vue";
import { faPlus, faSearch, faHandPointUp } from "@fortawesome/pro-regular-svg-icons";
import InputText from "@/components/input-text.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DataTable from "@/components/data-table.vue";
import DropdownContext from "@/components/dropdown-context.vue";
import DropdownItem from "@/components/dropdown-item.vue";
import BadgeSecondary from "@/components/badge-secondary.vue";
import { computed, ref } from "vue";
import usePaginatedProjectList from "@/composables/projects/paginated-project-list";
import ContentLoader from "@/components/content-loader.vue";
import { makeCoreUrl, navigateToCoreUrl } from "@/helpers/routing";
import ListProjectsTransferModal from "@/views/projects/dashboard/transfer-project-modal.vue";
import ListProjectsConfirmTransferModal from "@/views/projects/dashboard/confirm-project-transfer-modal.vue";
import CloseProjectModal from "@/views/projects/dashboard/close-project-modal.vue";
import type { PaginatedProject } from "@/api/projects/get-paginated-project-list";
import { useDebounce } from "@vueuse/core";
import {
  usePageQueryParameter,
  usePageSizeQueryParameter,
  useQueryParameter, useSortingQueryParameter
} from "@/composables/query-parameters";
import { z } from "zod";
import DataTablePagination from "@/components/data-table-pagination.vue";
import DataTableHeader from "@/components/data-table-header.vue";
import DataTableCell from "@/components/data-table-cell.vue";
import PageContent from '@/components/page-content.vue'
import ButtonHighlighted from '@/components/button-highlighted.vue'

type PendingTransfer = PaginatedProject['transfers'][number]
type Project = PaginatedProject

const creatingTransfer = ref<{ type: PendingTransfer['type'], projectId: number }>()
const actioningTransfer = ref<{ projectId: number, projectName: string, transfer: PendingTransfer }>()
const closingProject = ref<{ projectId: number, projectName: string }>()

const page = usePageQueryParameter()
const pageSize = usePageSizeQueryParameter()
const sorting = useSortingQueryParameter([{ field: 'id', direction: 'desc' }])
const filter = useQueryParameter('filter', z.string().trim().min(1))
const filterDebounced = useDebounce(filter, 500)

const {
  data,
  isFetching,
  isError,
} = usePaginatedProjectList({
  pageSize,
  page,
  filter: filterDebounced,
  sorting,
})

const projectsAsArray = computed(() => data.value?.records || [])
const { data: authenticatedUser, hasAbility } = useAuthenticatedUserDetails()

function actionTransfer(project: Project, transfer: PendingTransfer) {
  actioningTransfer.value = {
    projectId: project.id,
    projectName: project.name,
    transfer
  }
}

function getOutgoingTransferOfType(type: PendingTransfer['type'], project: Project) {
  if (!authenticatedUser.value) {
    return undefined
  }

  return project.transfers.find(
    x => x.type === type && x.fromUserId === authenticatedUser.value.id
  )
}

function getIncomingTransferOfType(type: PendingTransfer['type'], project: Project) {
  if (!authenticatedUser.value) {
    return undefined
  }

  return project.transfers.find(
    x => x.type === type && x.toUserId === authenticatedUser.value.id
  )
}
</script>

<template>
  <VerticallyCentered v-if="!hasAbility('projects:create') && !isFetching && !isError && projectsAsArray.length < 1">
    <HeroSplash
      title="Your current access doesn't include Projects."
      sub-title="Once granted, they'll appear here."
      :image-url="heroSplashImage"
    />
  </VerticallyCentered>

  <VerticallyCentered v-else-if="!filterDebounced && !isFetching && !isError && projectsAsArray.length < 1">
    <HeroSplash
      title="Manage your solar projects with ease."
      sub-title="Create your first Project to start."
      :image-url="heroSplashImage"
      action-button-text="New Project"
      :action-button-icon="faPlus"
      @actioned="navigateToCoreUrl('ProjectLead/Create')" />
  </VerticallyCentered>

  <template v-else>
      <PageHeader>
        <div>
          <ButtonPrimary :icon="faPlus" v-if="hasAbility('projects:create')" @click="navigateToCoreUrl('ProjectLead/Create')">New Project</ButtonPrimary>
        </div>
        <InputText placeholder="Search" v-model:value="filter">
          <template #suffix><FontAwesomeIcon :icon="faSearch" /></template>
        </InputText>
      </PageHeader>

      <PageContent>
        <VerticallyCentered v-if="isError">
          <HeroSplash title="An unexpected error has occurred." sub-title="Please try reloading the page." />
        </VerticallyCentered>

        <ContentLoader v-else :loading="isFetching" :variant="data !== undefined ? 'overlay' : 'table'">
          <DataTable
            v-model:sorting="sorting"
            :row-attrs="(record) =>  ({ 'class': { [$style.closed]: record.flags.isClosed }, onDblclick: () => navigateToCoreUrl(`ProjectLead/Edit?id=${encodeURIComponent(record.encryptedId)}`) })"
            :records="projectsAsArray">
              <template #message:empty>We couldn't find any projects that match your search.</template>

              <template #headers>
                <DataTableHeader name="id">Project</DataTableHeader>
                <DataTableHeader name="name" />
                <DataTableHeader name="owner" />
                <DataTableHeader name="assignee" />
                <DataTableHeader name="organisation" />
                <DataTableHeader>Action</DataTableHeader>
              </template>
            :row-attributes="({ record }) =>  ({
              class: [{ [$style.closed]: record.flags.isClosed }, $style.record],
              'on:dblclick': () => navigateToCoreUrl(`ProjectLead/Edit?id=${encodeURIComponent(record.encryptedId)}`)
            })"

              <template #record="{ record }">
                <DataTableCell>{{ record.id }}</DataTableCell>
                <DataTableCell>{{ record.name }}</DataTableCell>
                <DataTableCell>{{ record.ownerName }}</DataTableCell>
                <DataTableCell>{{ record.assigneeName }}</DataTableCell>
                <DataTableCell>{{ record.organisationName }}</DataTableCell>
                <DataTableCell>
                  <ButtonHighlighted
                    v-if="getIncomingTransferOfType('owner', record)"
                    :icon="faHandPointUp"
                    @click="actionTransfer(record, getIncomingTransferOfType('owner', record)!)">
                    Transfer Request
                  </ButtonHighlighted>
                  <ButtonHighlighted
                    v-else-if="getIncomingTransferOfType('assignee', record)"
                    :icon="faHandPointUp"
                    @click="actionTransfer(record, getIncomingTransferOfType('assignee', record)!)">
                    Assignment Request
                  </ButtonHighlighted>
                  <BadgeSecondary
                    v-else-if="getOutgoingTransferOfType('owner', record)">
                    Transfer Request Pending
                  </BadgeSecondary>
                  <BadgeSecondary
                    v-else-if="getOutgoingTransferOfType('assignee', record)">
                    Assignment Request Pending
                  </BadgeSecondary>
                </DataTableCell>
              </template>

            <template #context="{ record }">
              <DropdownContext v-if="record.flags.isOpen && (record.flags.userCanClose || record.flags.userCanView || record.flags.userCanEdit || record.flags.userCanTransfer)">
                <DropdownItem v-if="record.flags.userCanEdit || record.flags.userCanView" :href="makeCoreUrl(`ProjectLead/Edit?id=${encodeURIComponent(record.encryptedId)}`)">
                  {{ record.flags.userCanEdit ? 'Edit' : 'View' }}
                </DropdownItem>
                <DropdownItem v-if="record.flags.userCanTransfer" @click="creatingTransfer = { type: 'owner', projectId: record.id }">Transfer ownership</DropdownItem>
                <DropdownItem v-if="record.flags.userCanTransfer" @click="creatingTransfer = { type: 'assignee', projectId: record.id }">Assign project</DropdownItem>
                <DropdownItem v-if="record.flags.userCanClose" @click="closingProject = { projectId: record.id, projectName: record.name }">Close project</DropdownItem>
              </DropdownContext>
            </template>
          </DataTable>

          <DataTablePagination :page="data!.paging.page"
                               :page-size="data!.paging.pageSize"
                               :total-records="data!.paging.totalRecords"
                               @update:page="page = $event"
                               @update:page-size="pageSize = $event" />
        </ContentLoader>

        <ListProjectsTransferModal v-if="creatingTransfer" v-bind="creatingTransfer" @close="creatingTransfer = undefined" />
        <ListProjectsConfirmTransferModal v-else-if="actioningTransfer" v-bind="actioningTransfer" @close="actioningTransfer = undefined" />
        <CloseProjectModal v-else-if="closingProject" v-bind="closingProject" @close="closingProject = undefined" />
      </PageContent>
  </template>
</template>

<style module lang="scss">
@import "@/assets/variables";

.closed td {
  color: $table-cell-disabled-color;
}

.record td:not(:last-child) {
  cursor: pointer;
}
</style>
