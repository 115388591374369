<script setup lang="ts">
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import InputText from '@/components/input-text.vue'
import { faMagnifyingGlass, faX } from '@fortawesome/pro-regular-svg-icons'
import { computed, ref } from 'vue'
import ModalContent from '@/components/modal/modal-content.vue'
import { format } from 'date-fns/format'
import ModalFooter from '@/components/modal/modal-footer.vue'
import ButtonSecondary from '@/components/button-secondary.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DataTableCell from '@/components/data-table-cell.vue'
import DataTableHeader from '@/components/data-table-header.vue'
import DataTable from '@/components/data-table.vue'

defineProps<{ assetCode: string }>()

const search = ref('')
const records = computed(
  () => {
    const searchValueNormalized = search.value.toLowerCase()

    return [
      { activity: 'Declined', user: 'Vincent Labuschagne', date: new Date() },
      { activity: 'Approved', user: 'Sune van Heesen', date: new Date() },
      { activity: 'Approved', user: 'Jongilizwe Gqozo', date: new Date() }
    ].filter(
      x => search.value === '' || (
        x.activity.toLowerCase().includes(searchValueNormalized) ||
        x.user.toLowerCase().includes(searchValueNormalized) ||
        format(x.date, 'dd MMM yyyy').toLowerCase().includes(searchValueNormalized)
      )
    )
  }
)

</script>

<template>
  <ModalContainer v-slot="{ close }" :width="725">
    <ModalTitle class="d-flex justify-content-between">
      <div>
        Activity - Featherwood Retirement Village
        <br>
        <small>(Asset: {{ assetCode }} | Project: 2651)</small>
      </div>
      <InputText placeholder="Search" v-model:value.trim="search">
        <template #suffix>
          <FontAwesomeIcon :icon="faMagnifyingGlass" />
        </template>
      </InputText>
    </ModalTitle>
    <ModalContent>
      <DataTable :records="records">
        <template #headers>
          <DataTableHeader>Activity</DataTableHeader>
          <DataTableHeader>User</DataTableHeader>
          <DataTableHeader>Date</DataTableHeader>
        </template>
        <template #record="{ record }">
          <DataTableCell>{{ record.activity }}</DataTableCell>
          <DataTableCell>{{ record.user }}</DataTableCell>
          <DataTableCell>{{ format(record.date, 'dd MMM yyyy') }}</DataTableCell>
        </template>
      </DataTable>
    </ModalContent>
    <ModalFooter class="text-end">
      <ButtonSecondary :icon="faX" @click="close">Close</ButtonSecondary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">

</style>
