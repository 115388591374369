import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export interface BaseButtonProps {
  as?: 'button' | 'link',
  shadow?: boolean
  rounded?: boolean
  size?: 'small' | 'large'
  icon?: IconDefinition
  iconPosition?: 'before' | 'after'
  animation?: 'spin' | 'pulse' | 'bounce' | 'shake' | 'beat' | 'fade' | 'beatFade' | 'spinPulse'
  preset?: 'loading'
  disabled?: boolean
  required?: boolean
}

export const BaseButtonDefaults = {
  as: 'button',
  rounded: true,
  shadow: true,
  iconPosition: 'before'
} as const
