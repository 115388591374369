import makeApiClient, { Type } from '@/api/api'
import type { Projects } from "@/types";

type Argument = {
  type: Projects.TransferType
  projectId: number
}

const { sendDeleteRequest } = makeApiClient(Type.Core)

export default async function rejectProjectTransfer({ type, projectId } : Argument) {
  await sendDeleteRequest(`projects/${projectId}/transfers/${type}`)
}
