import { assertSchema } from '@/helpers/assert-schema'
import { buildApiUrl, sendGetRequest, Type } from '@/api/api'
import { UserFlagsSchema } from '@/api/users/schemas'

export async function getUserFlags(id: string | 'me') {
  const url = buildApiUrl(Type.Core, `users/${id}/flags`)
  const { data } = await sendGetRequest(url)

  assertSchema(data, UserFlagsSchema)

  return data
}
