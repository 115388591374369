import { createClient } from '@/shared/api/client'
import { BaseUrls } from '@/shared/utils/urls/constants'
import { validateResponse } from '@/shared/api/validation'
import { z } from 'zod'

const client = createClient(BaseUrls.AssetManagement)

export async function fetchUploadedFile (id: string) {
  const parsed = await validateResponse(
    client.sendGet(`uploads/${id}`),
    z.object({
      data: z.object({
        id: z.string(),
        fileName: z.string(),
        size: z.number(),
      })
    })
  )

  return {
    ...parsed.data,
    downloadUrl: client.buildUrl(`uploads/${id}/contents`)
  }
}
