import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import type { Colors } from '@/shared/types/colors'


export interface BaseButtonProps {
  shadow?: boolean
  rounded?: boolean
  size?: 'small' | 'large'
  icon?: IconDefinition
  iconPosition?: 'before' | 'after'
  state?: 'loading'|'disabled'
}

export interface PrimaryButtonProps extends BaseButtonProps { }
export interface SecondaryButtonProps extends BaseButtonProps { outline?: ButtonOutlineColors }
export interface HighlightedButtonProps extends BaseButtonProps { }

export const baseButtonDefaults = {
  shadow: true,
  rounded: true,
  iconPosition: 'before'
} as const

export const primaryButtonDefaults = { ...baseButtonDefaults }
export const secondaryButtonDefaults = { ...baseButtonDefaults }
export const highlightedButtonDefaults = { ...baseButtonDefaults, shadow: false }

export type ButtonOutlineColors = Colors
