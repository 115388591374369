import type { RouteRecordRaw } from 'vue-router'
import ViewLayoutAuth from '@/views/layouts/auth.vue'
import ViewAuthLogin from '@/views/auth/login.vue'

export default [
  { path: '/login',
    component: ViewLayoutAuth,
    meta: {
    requiresAuth: false,
      pageTitle: 'Login'
    },
    children: [
      { path: '', component: ViewAuthLogin, name: 'auth.login' }
    ]
  }
] satisfies RouteRecordRaw[] as RouteRecordRaw[]
