import { z } from 'zod'

export function assertSchemaAndCoerce<TData, TSchema extends z.Schema>(data: TData, schema: TSchema): z.infer<TSchema> {
  const { success, error, data: coerced } = schema.safeParse(data)

  if (!success) {
    throw error
  }

  return coerced
}
