<script setup lang="ts">
import { UrlPaths } from '@/shared/utils/urls/constants'
import { buildAssetManagementUrl, buildCoreUrl } from '@/shared/utils/urls/builders'
import {
  faArrowLeft,
  faDownload,
  faUpload,
  faCircleCheck,
  faExclamationTriangle, faCircleNotch, faHandPointUp, faXmark, faFloppyDisk, faX
} from '@fortawesome/pro-regular-svg-icons'
import LinkButtonSecondary from '@/shared/components/LinkButtonSecondary.vue'
import CardContainer from '@/components/Card/card-container.vue'
import CardHeader from '@/components/Card/card-header.vue'
import CardHeaderItem from '@/components/Card/card-header-item.vue'
import DropdownContext from '@/components/dropdown-context.vue'
import DropdownItem from '@/components/dropdown-item.vue'
import CardContent from '@/components/Card/card-content.vue'
import InputLabel from '@/components/input-label.vue'
import TooltipIcon from '@/shared/components/TooltipIcon.vue'
import InputDate from '@/components/input-date.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createReusableTemplate } from '@vueuse/core'
import ButtonSecondary from '@/components/button-secondary.vue'
import ButtonHighlighted from '@/components/button-highlighted.vue'
import { useModalState } from '@/shared/composables/useModalState'
import AssetYieldsActivityLog from '@/features/assets/components/AssetYieldsActivityLog.vue'
import { useQuery } from '@tanstack/vue-query'
import { yieldQueries } from '@/features/assets/queries'
import ButtonPrimary from '@/components/button-primary.vue'
import { useYieldApproval, useYieldRejection } from '@/features/assets/mutations'
import DrawExitStatusModal from '@/features/assets/components/DrawExitStatusModal.vue'
import { format } from 'date-fns/format'
import FileUpload from '@/shared/components/FileUpload.vue'
import { z } from 'zod'
import { ref, reactive } from 'vue'
import FileUploadList from '@/shared/components/FileUploadList.vue'
import { randomDelayBetween } from '@/helpers'

const props = defineProps<{ assetCode: string }>()

const { data: yieldData, isError: isYieldError, isLoading: isYieldLoading } = useQuery(yieldQueries.fetch(() => props.assetCode))
const { mutate: approve } = useYieldApproval(props.assetCode)
const { mutate: reject } = useYieldRejection(props.assetCode)
const { show: showActivityLog, hide: hideActivityLog, isVisible: isActivityLogShown } = useModalState()
const { show: showDrawExit, hide: hideDrawExit, isVisible: isDrawExitShown } = useModalState()
const [DefineUploadButton, UploadButton] = createReusableTemplate()
const [DefineUploadedFiles, UploadedFiles] = createReusableTemplate<{ uploads: { id: string, state?: 'ok'|'error'|'processing' }[] }>({ inheritAttrs: false })

const uploadSchema = z.object({
  data: z.object({
    id: z.string()
  })
})

const completedUploads = reactive<Record<string, string>>({ });
const completedUploadStates = reactive<Record<string, 'ok' | 'processing' | 'error'>>({ });


function addCompletedUpload(yieldType: string, data: z.infer<typeof uploadSchema>, closeModal: ()=> void) {
  console.log('adding completed upload', { yieldType, data })

  completedUploads[yieldType] = data.data.id
  closeModal()

  setTimeout(
    () => {
      console.log(`changing [${yieldType}] to 'processing'`)
      completedUploadStates[yieldType] = 'processing'

      setTimeout(
        () => {
          const target = Math.random() < 0.5 ? 'error' : 'ok'
          console.log(`changing [${yieldType}] to '${target}'`)
          completedUploadStates[yieldType] = target
        },
        4500
      )
    },
    1500
  )
}

function getCompletedUpload(yieldType: string) {
  return yieldType in completedUploads ? [{ id: completedUploads[yieldType], state: completedUploadStates[yieldType] }] : []
}

function dd(id: string, fn: () => Promise<void>) {
  !async function() {
    await randomDelayBetween(1000, 2500)

    for (const key in completedUploads) {
      if (completedUploads[key] === id) {
        delete completedUploads[key]
        delete completedUploadStates[key]
      }
    }

    console.log(`Triggering delete for uploaded file [${id}]`)
    fn().then(
      () => console.log('Finishing deleting uploaded file from api.')
    )
  }()
}
</script>

<template>
  <AssetYieldsActivityLog @close="hideActivityLog" v-if="isActivityLogShown" :asset-code="assetCode" />
  <DrawExitStatusModal v-if="isDrawExitShown" @close="hideDrawExit" :asset-code="assetCode" />

  <DefineUploadButton v-slot="{ yieldType }">
    <FileUpload
      class="w-100"
      :url="buildAssetManagementUrl('uploads')"
      :schema="uploadSchema"
      :max="1"
      @complete="$event.uploaded.length > 0 ? addCompletedUpload(yieldType, $event.uploaded[0], $event.close) : void(0)"
    />
  </DefineUploadButton>

  <DefineUploadedFiles v-slot="{ uploads }">
    <FileUploadList :uploads="uploads" @delete="dd" />
  </DefineUploadedFiles>

  <LinkButtonSecondary :to="buildCoreUrl(UrlPaths.AssetManagement.Dashboard)" :icon="faArrowLeft">
    Back to Dashboard
  </LinkButtonSecondary>

  <CardContainer class="mt-4">
    <CardHeader size="small">
      <CardHeaderItem>Yields</CardHeaderItem>
      <CardHeaderItem>
        <DropdownContext>
          <DropdownItem @click="showActivityLog">Activity</DropdownItem>
        </DropdownContext>
      </CardHeaderItem>
    </CardHeader>

    <CardContent>
      <div :class="$style['row-top']" class="d-grid align-items-center gap-3">
          <InputLabel class="text-nowrap">
            Guarantee Expected End Date
            <TooltipIcon text="Projected end date of the guarantee period, 12 months from the asset's sale date." class="ms-1" />
          </InputLabel>
          <InputDate inline value="2025-02-01" />

          <div class="spacer"></div>

          <InputLabel class="text-nowrap">
            Design Output
            <TooltipIcon text="Solar Yield<br>(from PV system)" class="ms-1" />
          </InputLabel>
          <span class="text-nowrap">1,650 <span class="text-muted">kWh / kWp</span></span>

          <template v-if="yieldData?.guaranteeEndDate">
            <InputLabel class="text-nowrap">
              Guarantee Actual End Date
              <TooltipIcon text="The confirmed date when the asset exits the guarantee period and transitions to the warranty period." class="ms-1" />
            </InputLabel>
            <span>{{ format(yieldData!.guaranteeEndDate, 'yyyy/MM/dd') }}</span>
          </template>
          <div v-else style="grid-column: span 2"></div>

          <div class="spacer"></div>

          <InputLabel class="text-nowrap">
            Projected Usage
            <TooltipIcon text="Consumption Yield<br>(customer energy usage)" class="ms-1" />
          </InputLabel>
          <span class="text-nowrap">1,343 <span class="text-muted">kWh / kWp</span></span>
      </div>

      <div :class="$style['row-yields']" class="d-grid mt-4">
        <div :class="$style.shaded">
          <!-- headers -->
          <div :class="$style.title" class="ps-0">Type</div>
          <div :class="$style.title">
            PVSys<br>Report (CSV)
            <TooltipIcon text="Containing hourly expected energy (P90) data for 12 months." class="ms-1" />
          </div>
          <div :class="$style.title">
            PVSys<br>Report (PDF)
            <TooltipIcon text="PVSys simulation report containing the system size." class="ms-1" />
          </div>
          <div :class="$style.title">
            Calculation<br>Summary (PDF)
            <TooltipIcon text="A summary document of how guarantee yield is calculated." class="ms-1" />
          </div>
          <div :class="$style.title">
            Assumptions<br>Report (PDF)
            <TooltipIcon text="Report of assumptions used yield calculations (e.g.: downtime periods)." class="ms-1" />
          </div>
          <div :class="$style.title" class="text-end">Yield</div>
          <div :class="$style.title" class="text-end">
            System Size
            <TooltipIcon text="As per Sale of Business Agreement." class="ms-1" />
          </div>
          <div :class="$style.title" class="pe-0">Action</div>
          <!-- /headers -->

          <!-- guarantee yield #1 -->
          <div style="grid-row: span 2" class="align-self-start fw-bold ps-0">
            Guarantee<br>
            Period<br>
            Yield <TooltipIcon text="Year 1<br>typically 98% of P90." class="ms-1" />
          </div>
          <div class="align-self-center"><UploadButton yield-type="guarantee:pvsys.csv" /></div>
          <div class="align-self-center"><UploadButton yield-type="guarantee:pvsys.pdf" /></div>
          <div class="align-self-center"><UploadButton yield-type="guarantee:calc.pdf" /></div>
          <div class="align-self-center"><UploadButton yield-type="guarantee:assumptions.pdf" /></div>
          <div class="align-self-center text-end">30,305 <span class="text-muted">kWh / kWp</span></div>
          <div class="align-self-center text-end">21,346 <span class="text-muted">kWp</span></div>
          <div class="pe-0 align-self-center">
            <template v-if="yieldData?.state === 'initial:approving'">
              <ButtonHighlighted :icon="faHandPointUp" :shadow="true" @click="approve('guarantee')">Approve</ButtonHighlighted>
              <ButtonSecondary :icon="faXmark" class="ms-2" @click="reject('guarantee')">Reject</ButtonSecondary>
            </template>
          </div>
          <!-- /guarantee yield #1 -->

          <!-- guarantee yield #2 -->
          <div class="pt-2 align-self-start" style="grid-column-start: 2">
            <UploadedFiles :uploads="getCompletedUpload('guarantee:pvsys.csv')" />
          </div>
          <div class="pt-2 align-self-start">
            <UploadedFiles :uploads="getCompletedUpload('guarantee:pvsys.pdf')" />
          </div>
          <div class="pt-2 align-self-start">
            <UploadedFiles :uploads="getCompletedUpload('guarantee:calc.pdf')" />
          </div>
          <div class="pt-2 align-self-start">
            <UploadedFiles :uploads="getCompletedUpload('guarantee:assumptions.pdf')" />
          </div>
          <div style="grid-column: 6 / -1"></div>
          <!-- /guarantee yield #2 -->

          <div style="grid-column-start: 7" class="text-end fw-bold mt-3 ps-0 pe-0" :class="[$style.title, $style['without-border']]">
            System Size
            <TooltipIcon text="As per Power Purchase Agreement." />
          </div>
          <div></div>

          <!-- consumption yield #1 -->
          <span style="grid-row: span 2" class="align-self-start fw-bold ps-0">
            Consumption<br>
            Period<br>
            Yield <TooltipIcon text="The yield guaranteed to the customer under the PPA." class="ms-1" />
          </span>
          <div class="align-self-center" style="grid-column-start: 3"><UploadButton yield-type="consumption:pvsys.pdf" /></div>
          <div class="align-self-center"><UploadButton yield-type="consumption:calc.pdf" /></div>
          <div class="align-self-center"><UploadButton yield-type="consumption:assumptions.pdf" /></div>
          <div class="align-self-center text-end">30,305 <span class="text-muted">kWh / kWp</span></div>
          <div class="align-self-center text-end">21,346 <span class="text-muted">kWp</span></div>
          <div class="pe-0 align-self-center">
            <template v-if="yieldData?.state === 'initial:approving'">
              <ButtonHighlighted :icon="faHandPointUp" :shadow="true" @click="approve('consumption')">Approve</ButtonHighlighted>
              <ButtonSecondary :icon="faXmark" class="ms-2" @click="reject('consumption')">Reject</ButtonSecondary>
            </template>
          </div>
          <!-- /consumption yield #1 -->

          <!-- consumption yield #2 -->
          <div class="pt-2 align-self-start" style="grid-column-start: 3">
            <UploadedFiles :uploads="getCompletedUpload('consumption:pvsys.pdf')" />
          </div>
          <div class="pt-2 align-self-start">
            <UploadedFiles :uploads="getCompletedUpload('consumption:calc.pdf')" />
          </div>
          <div class="pt-2 align-self-start">
            <UploadedFiles :uploads="getCompletedUpload('consumption:assumptions.pdf')" />
          </div>
          <div style="grid-column: 6 / -1"></div>
          <!-- /consumption yield #2 -->
        </div>

        <div :class="$style.unshaded">
          <!-- warranty yield titles -->
          <div :class="$style.title"></div>
          <div :class="$style.title" class="fw-bold">
            Exit Draw Field (PDF)
          </div>
          <div :class="$style.title" style="grid-column: 3 / 6"></div>
          <div :class="$style.title" class="text-end">Yield</div>
          <div :class="$style.title" class="text-end">
            System Size
            <TooltipIcon text="Based on the Draw Exit." class="ms-1" />
          </div>
          <div :class="$style.title" class="pe-0">Action</div>
          <!-- /warranty yield titles -->

          <!-- warranty yield #1 -->
          <div style="grid-row: span 2" class="align-self-start fw-bold ps-0">
            Warranty<br>
            Period<br>
            Yield <TooltipIcon text="Based on actual production data post year 1." class="ms-1" />
          </div>
          <div class="align-self-start"><UploadButton yield-type="warranty:draw.pdf" /></div>
          <div style="grid-column: 3 / 6"></div>
          <div class="text-muted text-end align-self-center" :class="$style.yield">0 kWh / 0 kWp</div>
          <div class="text-muted text-end align-self-center" :class="$style['system-size']">0 kWp</div>
          <div class="pe-0">
            <template v-if="yieldData?.state === 'guarantee:approving'">
              <ButtonHighlighted :icon="faHandPointUp" :shadow="true" @click="approve('warranty')">Approve</ButtonHighlighted>
              <ButtonSecondary :icon="faXmark" class="ms-2" @click="reject('warranty')">Reject</ButtonSecondary>
            </template>
          </div>
          <!-- /warranty yield #1 -->

          <!-- warranty yield #2 -->
          <div class="pt-2 align-self-start" style="grid-column-start: 2">
            <UploadedFiles :uploads="getCompletedUpload('warranty:draw.pdf')" />
          </div>
          <!-- /warranty yield #2 -->
        </div>

      </div>

      <div :class="$style['monthly-yields']">
        <div class="text-start" :class="$style.title">Month</div>
        <div :class="$style.title">Jan</div>
        <div :class="$style.title">Feb</div>
        <div :class="$style.title">Mar</div>
        <div :class="$style.title">Apr</div>
        <div :class="$style.title">May</div>
        <div :class="$style.title">Jun</div>
        <div :class="$style.title">Jul</div>
        <div :class="$style.title">Aug</div>
        <div :class="$style.title">Sep</div>
        <div :class="$style.title">Oct</div>
        <div :class="$style.title">Nov</div>
        <div :class="$style.title">Dec</div>

        <div class="text-start fw-bold">Guarantee Period - Expected Energy (kWh)</div>
        <div>31,456</div>
        <div>29,890</div>
        <div>26,170</div>
        <div>25,547</div>
        <div>27,346</div>
        <div>30,370</div>
        <div>26,098</div>
        <div>26,170</div>
        <div>25,547</div>
        <div>28,122</div>
        <div>63,717</div>
        <div>12,000</div>

        <div class="text-start fw-bold">Consumption Period - Expected Energy (kWh)</div>
        <div>31,456</div>
        <div>29,890</div>
        <div>26,170</div>
        <div>25,547</div>
        <div>27,346</div>
        <div>30,370</div>
        <div>26,098</div>
        <div>26,170</div>
        <div>25,547</div>
        <div>28,122</div>
        <div>63,717</div>
        <div>12,000</div>

        <div class="text-start fw-bold">Warranty Period - Expected Energy (kWh)</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </CardContent>

  </CardContainer>

  <div class="hstack mt-4">
    <ButtonPrimary :icon="faFloppyDisk">Save</ButtonPrimary>
    <ButtonSecondary class="ms-2" :icon="faXmark">Cancel</ButtonSecondary>
    <template v-if="yieldData?.state === 'guarantee:approved'">
      <div class="vr ms-3 me-3"></div>
      <ButtonHighlighted shadow :icon="faHandPointUp" @click="showDrawExit">Draw Exit</ButtonHighlighted>
    </template>
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

.row-top {
  grid-template-columns: min-content 185px 50% min-content max-content;

  .spacer {
    width: 100%;
  }
}

.title {
  align-content: end;
  font-weight: bold;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;

  &.without-border {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.row-yields {
  grid-template-columns: min-content repeat(4, 1fr) repeat(2, auto) 180px;
  margin-left: #{-1 * $card-spacer-x};
  margin-right: #{-1 * $card-spacer-x};
  padding-left: #{$card-spacer-x};
  padding-right: #{$card-spacer-x};

  .row-start {
    padding-left: 0;
  }

  .row-end {
    padding-right: 0;
  }

  .unshaded > *, .shaded > * {
    padding-left: 10px;
    padding-right: 10px;
  }

  .shaded, .unshaded {
    display: grid;
    background: #f6f6f6;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    margin-left: #{-1 * $card-spacer-x};
    margin-right: #{-1 * $card-spacer-x};
    padding: #{$card-spacer-y} #{$card-spacer-y};
  }

  .unshaded {
    background: white;
  }
}

.monthly-yields {
  margin-top: 25px;
  display: grid;
  grid-template-columns: max-content repeat(12, 1fr);

  > div {
    padding: 5px;
    text-align: end;
  }

  > :global(.text-start) {
    padding-right: 40px;
  }
}
</style>
