<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { yieldQueries } from '@/features/assets/queries'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'
import BadgeYellow from '@/components/badge-yellow.vue'
import BadgeTeal from '@/components/badge-teal.vue'
import BadgeRed from '@/components/badge-red.vue'
import BadgePrimary from '@/components/badge-primary.vue'
import { computed } from 'vue'
import { start, reset } from '@/features/assets/api/fetchAssetYields'

const props = defineProps<{ assetCode: string }>()
const { data, isError, error, isFetching, refetch } = useQuery(yieldQueries.fetch(() => props.assetCode))

function init() {
  start()
  refetch()
}

function resetEvent() {
  reset()
  refetch()
}

const phase = computed(
  () => {
    const matched = data.value?.state.match(/^(guarantee|warranty)(?:$|:)/)

    if (!matched || matched.length !== 2) {
      return undefined
    }

    return matched[1] === 'guarantee' ? 'Guarantee' : 'Warranty/AMA'
  }
)

const state = computed(
  () => {
    if (!data.value) {
      return undefined
    }

    const { state, approvals } = data.value

    if (state.endsWith(':approved')) {
      return { component: BadgeTeal, text: 'Approved' }
    }

    if (state.endsWith(':rejected')) {
      return { component: BadgeRed, text: 'Rejected' }
    }

    if (!state.endsWith(':approving')) {
      return undefined
    }

    const suffix = approvals.required > 1
      ? ` (${approvals.received.length}/${approvals.required})`
      : ''

    return { component: BadgeYellow, text: `Pending Approval${suffix}` }
  }
)
</script>

<template>
  <div class="d-flex">
    <div style="margin-right: 40px;">
      <button type="button" @click="() => (data?.state === 'initial' ? init() : resetEvent())">{{ data?.state === 'initial' ? 'Start' : 'Reset' }}</button>
    </div>

    <div class="d-flex align-items-center">
      <FontAwesomeIcon v-if="isFetching && !phase && !state" :icon="faSpinnerThird" spin />
      <BadgeRed :class="$style.status" v-else-if="isError">Error in fetching status</BadgeRed>

      <template v-else>
        <div v-if="phase">
          <span class="fw-bold text-uppercase me-3">Yield Phase</span>
          <BadgePrimary :class="$style.status" v-if="phase">
            <FontAwesomeIcon v-if="isFetching" :icon="faSpinnerThird" spin />
            <template v-else>{{ phase }}</template>
          </BadgePrimary>
        </div>

        <div class="ms-4" v-if="state">
          <span class="fw-bold text-uppercase me-3">Status</span>
          <component :class="$style.status" :is="state.component">
            <FontAwesomeIcon v-if="isFetching" :icon="faSpinnerThird" spin />
            <template v-else>{{ state.text }}</template>
          </component>
        </div>
      </template>
    </div>
  </div>
</template>

<style module lang="scss">
.status {
  min-width: 149px;
}
</style>
