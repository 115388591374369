import { useQuery } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'

import { getUserList } from "@/api/users/users";

export default function useUserList(organisationId?: MaybeRefOrGetter<number | undefined>) {
  return useQuery({
    queryKey: ['users:list', organisationId],
    enabled: () => toValue(organisationId) !== undefined,
    queryFn: () => getUserList(toValue(organisationId)!)
  })
}
