import { queryOptions } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { fetchUploadedFile } from '@/shared/api/uploads/fetchUploadedFile'

export const uploadedFileQueries = {
  all: () => queryOptions({ queryKey: ['uploads'] }),

  fetch: (uploadId: MaybeRefOrGetter<string>) => queryOptions({
    queryKey: [...uploadedFileQueries.all().queryKey, uploadId],
    queryFn: () => fetchUploadedFile(toValue(uploadId)),
    meta: {
      logger: 'shared/uploadedFiles.fetch'
    }
  }),
} as const
