import { BaseUrls } from '@/shared/utils/urls/constants'

function buildUrl(
  base: URL,
  path: string|string[]
) {
  let built: string

  if (typeof path === 'string') {
    built = path.replace(/^\/+/, '')
  } else {
    built = path.map(v => encodeURIComponent(v)).join('/')
  }

  return new URL(built, base).toString()
}

export const buildCoreUrl = (path: string|string[]) => buildUrl(BaseUrls.Core, path)
export const buildCoreApiUrl = (path: string|string[]) => buildUrl(BaseUrls.CoreApi, path)
export const buildAssetManagementUrl = (path: string|string[]) => buildUrl(BaseUrls.AssetManagement, path)
