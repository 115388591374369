<script setup lang="ts">
import { inject } from 'vue'
import { CardInjectionKey } from '@/shared/types/injections'

const ctx = inject(CardInjectionKey)

if (ctx === undefined) {
  throw new Error("[CardContent] can only be used inside of a [Card] component.")
}

const headerPlacement = ctx.headerPlacement
const isOpen = ctx.isOpen

</script>

<template>
  <div :class="[$style['card-content'], $style[`header-${headerPlacement}`], { 'd-none': !isOpen }]">
    <slot />
  </div>
</template>

<style module lang="scss">
@import "@/assets/variables";

$border-radius: .625rem;

.header-outside {
  box-shadow: .125rem .125rem .25rem rgba(0, 0, 0, 0.16);
  border: .1px solid #d1d1d1;
  background: #fff;
  border-radius: #{$card-border-radius};
}

.card-content {
  padding: #{$card-spacer-y} #{$card-spacer-x};
}
</style>
