import type { YieldType } from '@/features/assets/types'
import { buildAssetManagementUrl } from '@/shared/utils/urls/builders'
import { sendPutRequest } from '@/api/api'
import { approve } from '@/features/assets/api/fetchAssetYields'
import { randomDelayBetween } from '@/helpers'

export async function approveYield(assetCode: string, type: YieldType) {
  const url = buildAssetManagementUrl(`assets/${assetCode}/yields/approvals`)
  const body = {
    data: {
      action: 'approve',
      type,
    }
  }

  console.log(`Pretending to send request %O to %s`, body, url)

  await randomDelayBetween(100, 300)
  approve()
}
