import makeApiClient, { Type } from '@/api/api'
import { z } from "zod";
import { assertSchema } from "@/helpers/assert-schema";

const { sendGetRequest } = makeApiClient(Type.Core)

export const schema = z.array(
  z.object({
    id: z.number().int().gt(0),
    name: z.string()
  })
)

export default async function getProjectClosureReasons() {
  const { data } = await sendGetRequest('projects/config/closure-reasons')

  assertSchema(data, schema)

  return data
}
