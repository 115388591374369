import { computed, type MaybeRefOrGetter, ref, toValue } from 'vue'
import { z, type ZodError } from 'zod'
import { ResponseError, SchemaError, validateResponse, ValidationError } from '@/shared/api/validation'

type OnFileUploadedCallback<TSchema extends z.Schema> = (value: { result: z.infer<TSchema>, file: File, index: number }) => void
type OnFileCompleteCallback = (value: { file: File, index: number, successful: boolean, reason?: ZodError }) => void
type OnFileErrorCallback = (value: { file: File, index: number, error?: ValidationError|ResponseError|SchemaError|unknown }) => void

type CompletedResult<TSchema extends z.Schema> = { uploaded: z.infer<TSchema>[], failed: unknown[] }

export function useFileUploads<TSchema extends z.Schema>({ url, schema } : { url: string|URL, schema: MaybeRefOrGetter<TSchema> }) {
  const currentPromise = ref<Promise<CompletedResult<TSchema>>>()
  const files = ref<{ state: 'pending'|'uploading'|'failed'|'uploaded', file: File, progress?: number }[]>([])

  const callbacks : {
    onFileComplete: OnFileCompleteCallback[]
    onFileUploaded: OnFileUploadedCallback<TSchema>[]
    onFileError: OnFileErrorCallback[]
  } = {
    onFileComplete: [],
    onFileUploaded: [],
    onFileError: [],
  } as const

  const isUploading = computed(() => currentPromise.value !== undefined)

  return {
    isUploading,

    files: computed(
      () => files.value.map(x => ({ state: x.state, file: x.file, progress: x.progress }))
    ),

    addFile(file: File) {
      files.value.push({ state: 'pending', file })
    },

    removeFile(file: File|number) {
      const indexToRemove = typeof file === 'number' ? file : files.value.findIndex(x => x.file === file)

      if (indexToRemove >= 0 && indexToRemove < files.value.length) {
        files.value.splice(indexToRemove, 1)
      }
    },

    onFileUploaded: (fn: OnFileUploadedCallback<TSchema>) => { callbacks.onFileUploaded.push(fn) },
    onFileError: (fn: OnFileErrorCallback) => { callbacks.onFileError.push(fn) },
    onFileComplete: (fn: OnFileCompleteCallback) => { callbacks.onFileComplete.push(fn) },

    upload() {
      // Nothing to upload.
      if (files.value.length < 1) {
        return Promise.resolve({ failed: [], uploaded: [] })
      }

      // If we have an upload running, then simply return it.
      if (currentPromise.value !== undefined) {
        return currentPromise.value
      }

      files.value = [...files.value].map(x => ({ ...x, state: 'uploading', progress: 0 }))

      return currentPromise.value = Promise.allSettled(
        files.value.map(
          (item, index) => new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.upload.onprogress = e => {
              files.value[index] = {
                ...files.value[index],
                progress: e.lengthComputable
                  ? Math.ceil(e.loaded / e.total * 100)
                  : -1
              }
            }

            // When we change to readyState 2 or 3 (HEADERS_RECEIVED or LOADING) it means we're waiting on the response to
            // be sent, and we can indicate this in the progress.
            xhr.onreadystatechange = function() {
              if (xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED || xhr.readyState === XMLHttpRequest.LOADING) {
                files.value[index] = {
                  ...files.value[index],
                  progress: undefined
                }
              }
            }

            // Ensure failures are communicated.
            xhr.onabort = () => reject()
            xhr.onerror = () => reject()

            // Attempt to parse response.
            xhr.onload = () => {
              try {
                validateResponse(
                  Promise.resolve(
                    new Response(
                      xhr.response,
                      {
                        status: xhr.status,
                        statusText: xhr.statusText,
                      }
                    )
                  ),
                  toValue(schema)
                ).then(resolve, reject)
              } catch (e: unknown|SyntaxError) {
                reject(e)
              }
            }

            const data = new FormData()
            data.set('file', item.file)

            xhr.open('POST', url)
            xhr.withCredentials = true
            xhr.send(data)
          }).then(
            (result: z.infer<TSchema>) => {
              files.value[index] = { ...files.value[index], state: 'uploaded', progress: undefined }

              callbacks.onFileUploaded.forEach(fn => fn({ result, file: files.value[index].file, index }))
              callbacks.onFileComplete.forEach(fn => fn({ file: files.value[index].file, index, successful: true }))

              return result
            },
            reason => {
              files.value[index] = { ...files.value[index], state: 'failed', progress: undefined }

              callbacks.onFileError.forEach(fn => fn({ file: files.value[index].file, index, error: reason }))
              callbacks.onFileComplete.forEach(fn => fn({ file: files.value[index].file, index, successful: false, reason }))

              throw reason
            }
          )
        )
      ).then(
        results => {
          // We have to move from the back of the array, so we don't mess up the indexing in the array.
          for (let index = results.length - 1; index >= 0; index--) {
            if (results[index].status === 'fulfilled') {
              files.value.splice(index, 1)
            }
          }

          return {
            failed: results
              .filter((x): x is PromiseRejectedResult => x.status === 'rejected')
              .map(x => x.reason as unknown),
            uploaded: results
              .filter((x): x is PromiseFulfilledResult<z.infer<TSchema>> => x.status === 'fulfilled')
              .map(x => x.value),
          }
        }
      ).finally(
        () => currentPromise.value = undefined
      )
    }
  }
}
