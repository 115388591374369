import { z } from 'zod'
export type Projectead = z.infer<typeof ProjectLeadSchema>

const CountrySchema = z.object({
  CountryId: z.number().default(0),
  Name: z.string().default(""),
  CPI: z.number().default(0),
  UtilityInflation: z.number().default(0),
  Azimuth: z.number().default(0),
  Tilt: z.number().default(0),
  CorporateTaxRate: z.number(),
  Currency: z.object({
    Id: z.number().default(0),
    Code: z.string().default(""),
    Symbol: z.string().default(""),
    Description: z.string().default(""),
  }),
  CurrencyId: z.number().default(0),
  MapConfigId: z.number().default(0),
  MapConfig: z.object({
    Id: z.number().default(0),
    Name: z.string().default(""),
    Latitude: z.number().default(0),
    Longitude: z.number().default(0),
    ZoomLevel: z.number().default(0),
    GeocodeName: z.string().default(""),
    HasRegions: z.boolean().default(false),
  }),
});
// Default object for ProjectLeadSchema
export const defaultProjectLead = {
    ProjectLeadId: 0,
    ProjectName: "",
    SiteAddressLine1: "",
    SiteAddressLine2: "",
    SiteAddressSuburb: "",
    SiteAddressCity: "",
    ProvinceId: 0,
    Province: {
      ProvinceId: 0,
      ProvinceDescription: "",
      CountryId: 0,
      Country: {
        CountryId: 0,
        Name: "",
        CPI: 0,
        UtilityInflation: 0,
        Azimuth: 0,
        Tilt: 0,
        CorporateTaxRate: 0,
        Currency: {
          Id: 0,
          Code: "",
          Symbol: "",
          Description: "",
        },
        CurrencyId: 0,
        MapConfigId: 0,
        MapConfig: {
          Id: 0,
          Name: "",
          Latitude: 0,
          Longitude: 0,
          ZoomLevel: 0,
          GeocodeName: "",
          HasRegions: false,
        },
      },
    },
    CountryId: 0,
    SitePostalCode: "",
    SiteGpsCoordinates: "",
    SiteMapZoomLevel: 0,
    DateTimeCreated: "",
    Azimuth: 0,
    Tilt: 0,
    SiteUtilityProviderId: 0,
    SiteUtilityProviderStructureId: 0,
    ProjectLeadGpsCoordinates: [],
    SiteSolarYield: 0,
    SystemLoss: 0,
    UseUniFiiYield: false,
    ProjectLeadStatusId: 0,
    ProjectLeadStatus: "",
    LeadOwnerUserId: "",
    LeadAssignedToUserId: "",
    PreviousLeadOwnerUserId: "",
    ChangingAssignedUserId: "",
    ChangingOwnerUserId: "",
    LeadAssignedToUser: {
      Salutation: "",
      FirstName: "",
      LastName: "",
      UserType: "",
      Company: "",
      Designation: "",
      Address_Street: "",
      Address_City: "",
      Address_Country: "",
      CreatedOn: "",
      IsFinancedApproved: null,
      OrganizationId: 0,
      UserStatus: 0,
      Password: "",
      UpdatedOn: "",
      UserName: "",
      Email: "",
      AccessAreaRightsViewModels: null,
      Id: "",
      SelectedUserProfileIds: [],
      FullName: "",
      PhoneNumber: "",
      UserProfiles: [],
      LastLoginDateTime: "",
      EmailConfirmed: false,
      ImageUrl: "",
      Claims: [],
    },
  };
  
  export  const ProjectLeadSchema = z.object({
    ProjectLeadId: z.number().default(0),
    ProjectName: z.string().default(''),
    SiteAddressLine1: z.string().nullable().default(""),
    SiteAddressLine2: z.string().nullable().default(""),
    SiteAddressSuburb: z.string().nullable().default(""),
    SiteAddressCity: z.string().nullable().default(""),
    ProvinceId: z.number().default(0),
    Province: z.object({
      ProvinceId: z.number().default(0),
      ProvinceDescription: z.string(),
      CountryId: z.number().default(0),
      Country: CountrySchema,
    }),
    CountryId: z.number().default(0),
    SitePostalCode: z.string().nullable().default(""),
    SiteGpsCoordinates: z.string().nullable().default(""),
    SiteMapZoomLevel: z.number().default(0),
    DateTimeCreated: z.string().default(""),
    Azimuth: z.number().nullable().default(0),
    Tilt: z.number().nullable().default(0),
    SiteUtilityProviderId: z.number().nullable().default(0),
    SiteUtilityProviderStructureId: z.number().nullable().default(0),
    ProjectLeadGpsCoordinates: z.array(z.unknown().default([])),
    SiteSolarYield: z.number().nullable().default(0),
    SystemLoss: z.number().default(0),
    UseUniFiiYield: z.boolean().default(false),
    ProjectLeadStatusId: z.number().default(0),
    ProjectLeadStatus: z.string().nullable().default(""),
    LeadOwnerUserId: z.string().default(""),
    LeadAssignedToUserId: z.string().default(""),
    PreviousLeadOwnerUserId: z.string().nullable().default(""),
    ChangingAssignedUserId: z.string().nullable().default(""),
    ChangingOwnerUserId: z.string().nullable().default(""),
    LeadAssignedToUser: z.object({
      Salutation: z.string().nullable().default(""),
      FirstName: z.string().default(""),
      LastName: z.string().default(""),
      UserType: z.string().nullable().default(""),
      Company: z.string().nullable().default(""),
      Designation: z.string().nullable().default(""),
      Address_Street: z.string().nullable().default(""),
      Address_City: z.string().nullable().default(""),
      Address_Country: z.string().nullable().default(""),
      CreatedOn: z.string().default(""),
      IsFinancedApproved: z.boolean().nullable(),
      OrganizationId: z.number().default(0),
      UserStatus: z.number().default(0),
      Password: z.string().nullable().default(""),
      UpdatedOn: z.string().nullable().default(""),
      UserName: z.string().default(""),
      Email: z.string().default(""),
      AccessAreaRightsViewModels: z.array(z.unknown().default([])).nullable(),
      Id: z.string().default(""),
      SelectedUserProfileIds: z.array(z.unknown().default([])).nullable().default([]),
      FullName: z.string().default(""),
      PhoneNumber: z.string().nullable().default(""),
      UserProfiles: z.array(z.unknown()).nullable().default([]),
      LastLoginDateTime: z.string().default(""),
      EmailConfirmed: z.boolean().default(false),
      ImageUrl: z.string().nullable().default(""),
      Claims: z.array(z.string().default("")),
    }),
  });