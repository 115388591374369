import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { type MaybeRefOrGetter, toValue } from 'vue'
import {
  updateProject,
  getProjectByAssetCode,
  updatePoNumber,
  getProjectDetailsByAssetCode, getProjectDetailsByProjectCode
} from '@/api/assets/projects'
import { createLogger } from "@/helpers/logging";

type Project = {
    id: number,
    organisationId: number,
    projectCode: number,
    customerExternalIdentifier: string,
    poNumber: string,
    utilityProviderId: number,
    name: string,
    description: string,
    addressLineOne: string,
    addressLineTwo: string,
    suburb: string,
    city: string,
    countryId: string,
    vatNo: string
}

export function useGetProjectByAssetCode(assetCode:MaybeRefOrGetter<string | undefined>){
    return useQuery({
        queryKey: ['assets:project',assetCode],
        meta: {
          logger: createLogger(`composables/projects/useGetProjectByAssetCode`)
        },
        queryFn: () => getProjectByAssetCode(toValue(assetCode)!),
        enabled() {
            const value = toValue(assetCode)

            return value !== undefined
        }
    })
}

export function useProjectDetails(by: MaybeRefOrGetter<'asset' | 'project'>, value: MaybeRefOrGetter<number|null|undefined>) {
  return useQuery({
    queryKey: ['projects:details', by, value],
    meta: {
      logger: createLogger('composables/projects/useProjectDetails')
    },
    enabled() {
      const reducedValue = toValue(value)

      return reducedValue !== null && reducedValue !== undefined && reducedValue > 0
    },
    queryFn: () => {
      const reducedBy = toValue(by)
      const reducedValue = toValue(value)!

      if (reducedBy === 'asset') {
        return getProjectDetailsByAssetCode(reducedValue.toString())
      } else {
        return getProjectDetailsByProjectCode(reducedValue)
      }
    }
  })
}

export function useUpdateProject(projectCode: number, assetCode : string, project: Project) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (poNumber: MaybeRefOrGetter<string>) => {
            project.poNumber = toValue(poNumber)
            return updateProject(projectCode, project)
        },
        onSuccess(){
            queryClient.invalidateQueries({queryKey: ['assets:project',assetCode]}).catch(() => {})
        }
    })
}

type ProjectPoNumber = {
    projectCode: MaybeRefOrGetter<number>,
    poNumber: MaybeRefOrGetter<string>
}

export function useUpdatePoNumber(assetCode: MaybeRefOrGetter<string>){
    const queryclient = useQueryClient()

    return useMutation({
        mutationFn: (projectPoNumber : ProjectPoNumber) => updatePoNumber({
            projectCode: toValue(projectPoNumber.projectCode),
            poNumber: toValue(projectPoNumber.poNumber)
        }),
        onSuccess(){
            queryclient.invalidateQueries({queryKey: ['assets:project',toValue(assetCode)]})
        }
    })
}
